import { BrainSearchMessageFeedbackFooterFragment$key } from "@/brain-search/internal/__generated__/BrainSearchMessageFeedbackFooterFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayErrorToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoIcon,
  DiscoIconButton,
  DiscoInput,
  DiscoText,
  DiscoTextButton,
} from "@disco-ui"
import { Popover, useTheme } from "@material-ui/core"
import { useState } from "react"
import { graphql, useFragment, useMutation } from "react-relay"
import { BrainSearchMessageFeedbackFooter__RateReponseMutation } from "./__generated__/BrainSearchMessageFeedbackFooter__RateReponseMutation.graphql"

const RATING_MESSAGE = [
  "Not helpful",
  "Out of date",
  "Too long",
  "Too short",
  "Inaccurate",
  "Poor quality",
]

interface Props {
  brainSearchMessageKey: BrainSearchMessageFeedbackFooterFragment$key
}

export default function BrainSearchMessageFeedbackFooter({
  brainSearchMessageKey,
}: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const [tellUsMore, showTellUsMore] = useState(false)
  const [customFeedbackComment, setCustomFeedbackComment] = useState("")

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const message = useFragment<BrainSearchMessageFeedbackFooterFragment$key>(
    graphql`
      fragment BrainSearchMessageFeedbackFooterFragment on BrainSearchMessage {
        id
        brainSearchMessageFeedback {
          rating
        }
      }
    `,
    brainSearchMessageKey
  )

  const [commit, isCommiting] =
    useMutation<BrainSearchMessageFeedbackFooter__RateReponseMutation>(graphql`
      mutation BrainSearchMessageFeedbackFooter__RateReponseMutation(
        $input: TrackBrainSearchMessageFeedbackInput!
      ) {
        trackBrainSearchMessageFeedback(input: $input) {
          node {
            brainSearchMessage {
              brainSearchMessageFeedback {
                rating
              }
            }
          }
        }
      }
    `)

  const isUpVoted = message.brainSearchMessageFeedback?.rating === 1 || false
  const isDownVoted = message.brainSearchMessageFeedback?.rating === -1 || false

  return (
    <>
      <div className={classes.messageFeedback}>
        {!isDownVoted && (
          <DiscoIconButton
            width={24}
            height={24}
            disabled={isCommiting || isUpVoted}
            onClick={() => rateResponse(1)}
            className={classes.iconButton}
            hoverColor={theme.palette.primary.main}
          >
            <DiscoIcon icon={"iconsax.like"} active={isUpVoted} />
          </DiscoIconButton>
        )}

        {!isUpVoted && (
          <DiscoIconButton
            width={24}
            height={24}
            disabled={isCommiting || isDownVoted}
            onClick={handleOpen}
            className={classes.iconButton}
            hoverColor={theme.palette.primary.main}
          >
            <DiscoIcon icon={"iconsax.dislike"} active={isDownVoted} />
          </DiscoIconButton>
        )}
      </div>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          classes: {
            root: classes.popover,
          },
        }}
      >
        {renderPopoverFields()}
      </Popover>
    </>
  )

  function renderPopoverFields() {
    if (tellUsMore) {
      return (
        <>
          <DiscoText variant={"heading-xs-700"}>{"Tell Us More"}</DiscoText>

          <DiscoInput
            inputProps={{
              maxLength: 100,
            }}
            size={"large"}
            placeholder={"Write more about your feedback here..."}
            multiline
            rows={4}
            onChange={(e) => setCustomFeedbackComment(e.target.value)}
          />
          <DiscoText
            color={"groovy.neutral.500"}
            align={"right"}
          >{`${customFeedbackComment.length}/100`}</DiscoText>

          <DiscoButton onClick={() => rateResponse(-1, customFeedbackComment)}>
            {"Share"}
          </DiscoButton>
        </>
      )
    }

    return (
      <>
        <DiscoText variant={"heading-xs-700"}>
          {"What didn't you like about this response?"}
        </DiscoText>

        <div className={classes.messages}>
          {RATING_MESSAGE.map((ratingMessage) => (
            <DiscoTextButton
              key={ratingMessage}
              textVariant={"body-sm"}
              className={classes.responseMessage}
              variant={"currentColor"}
              onClick={() => rateResponse(-1, ratingMessage)}
            >
              {ratingMessage}
            </DiscoTextButton>
          ))}
          <DiscoTextButton
            key={"Other"}
            textVariant={"body-sm"}
            className={classes.responseMessage}
            variant={"currentColor"}
            onClick={() => showTellUsMore(true)}
          >
            {"Other"}
          </DiscoTextButton>
        </div>
      </>
    )
  }

  function handleOpen(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(e.currentTarget)
  }

  function handleCancel() {
    setAnchorEl(null)
    setCustomFeedbackComment("")
    showTellUsMore(false)
  }

  function rateResponse(rating: number, comment?: string) {
    commit({
      variables: {
        input: {
          brainSearchMessageId: message.id,
          rating,
          comment,
        },
      },
      onError(error) {
        displayErrorToast(error)
      },
      onCompleted() {
        handleCancel()
      },
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  messageFeedback: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  popover: {
    padding: theme.spacing(2.5),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    borderRadius: theme.measure.borderRadius.large,
    minWidth: "440px",
  },
  messages: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: theme.spacing(1),
  },
  responseMessage: {
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.smedium,
    padding: theme.spacing(0.5, 1.25),
  },
  iconButton: {
    padding: theme.spacing(0.5),

    color: theme.palette.groovy.neutral[600],
    "& > svg": {
      color: theme.palette.groovy.neutral[600],
    },

    "&.Mui-disabled": {
      color: theme.palette.primary.main,
      "&:not(:hover) svg": {
        color: theme.palette.primary.main,
      },
    },
  },
}))
