import useBrainSearchBot from "@/admin/integrations/chat-bot/util/useBrainSearchBot"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { DiscoIcon, DiscoIconButton } from "@disco-ui"
import DiscoSidebarHeader from "@disco-ui/sidebar/DiscoSidebarHeader"
import { generatePath, useHistory } from "react-router-dom"

export default function BrainSearchSidebarHeader() {
  const bot = useBrainSearchBot()
  const history = useHistory()

  if (!bot) return null
  return (
    <DiscoSidebarHeader
      title={bot.name}
      icon={"stars"}
      actions={
        <DiscoIconButton
          onClick={handleClick}
          width={24}
          height={24}
          tooltip={"New AI Conversation"}
        >
          <DiscoIcon icon={"add"} width={24} height={24} />
        </DiscoIconButton>
      }
    />
  )

  function handleClick() {
    history.push(generatePath(ROUTE_NAMES.BRAIN.ROOT))
  }
}
