import { BrainParams } from "@/brain-search/internal/BrainSearchPage"
import DeleteBrainSearchButton from "@/brain-search/internal/DeleteBrainSearchButton"
import { BrainSearchMoreActionsButtonFragment$key } from "@/brain-search/internal/__generated__/BrainSearchMoreActionsButtonFragment.graphql"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import usePermissions from "@utils/hook/usePermissions"
import { graphql, useFragment } from "react-relay"
import { generatePath, useHistory, useParams } from "react-router-dom"

interface Props {
  brainSearchKey: BrainSearchMoreActionsButtonFragment$key
}

export default function BrainSearchMoreActionsButton({ brainSearchKey }: Props) {
  const classes = useStyles()
  const history = useHistory()
  const params = useParams<BrainParams>()

  const brainSearch = useFragment<BrainSearchMoreActionsButtonFragment$key>(
    graphql`
      fragment BrainSearchMoreActionsButtonFragment on BrainSearch {
        id
        ...usePermissionsFragment
        ...DeleteBrainSearchButtonFragment
      }
    `,
    brainSearchKey
  )

  const canManageBrainSearch = usePermissions(brainSearch).has("brain_search.manage")
  if (!canManageBrainSearch) return null

  return (
    <DiscoMoreActionsDropdown
      moreActionsButtonClassName={classes.moreActionsButton}
      testid={`more-actions`}
      menuButtonProps={{ size: "small" }}
    >
      <DeleteBrainSearchButton brainSearchKey={brainSearch} onDelete={onDelete}>
        {(buttonProps) => (
          <DiscoDropdownItem {...buttonProps} icon={"trash"} title={"Delete"} />
        )}
      </DeleteBrainSearchButton>
    </DiscoMoreActionsDropdown>
  )

  function onDelete() {
    // Redirect to empty state if we're deleting the brain search we're currently viewing
    if (params.searchId === brainSearch.id) {
      console.log({ params, brainSearch })
      history.push(generatePath(ROUTE_NAMES.BRAIN.ROOT))
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  moreActionsButton: {
    "&.Mui-selected": {
      "& svg": {
        color: theme.palette.common.black,
      },
      "& $rightContent": {
        "& svg": {
          color: theme.palette.common.black,
          "&:hover": {
            color: theme.palette.common.black,
          },
        },
      },
    },
    "&.Mui-selected:hover": {
      "& svg": {
        color: theme.palette.common.black,
      },
    },
    "&:hover": {
      background: "rgba(0,0,0,.1)",
    },
  },
}))
