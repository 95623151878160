import {
  initialEmailSignUpFormState,
  SIGNUP_FORM_STATE_TEXT_FIELD_NAMES,
} from "@/authentication/email-sign-up/form/util/emailSignUpFormConstants"
import { generateEmailSignUpFormValidationObjectFromApiError } from "@/authentication/email-sign-up/form/util/emailSignUpFormUtils"
import { useSignupUser } from "@/authentication/signup/util/useSignupUser"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import SocialLogin, { SocialLoginProvider } from "@components/social-login/SocialLogin"
import {
  displayErrorToast,
  displayErrorToastWithArgs,
} from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoDivider,
  DiscoFormControl,
  DiscoInput,
  DiscoLink,
  DiscoPasswordInput,
  DiscoText,
  DiscoTextButton,
} from "@disco-ui"
import * as FS from "@fullstory/browser"
import { Turnstile } from "@marsidev/react-turnstile"
import { isApiError } from "@utils/error/errorUtils"
import { userSystemTimezone } from "@utils/timezone/timezoneConstants"
import { TestIDProps, ValueOf } from "@utils/typeUtils"
import { useQueryParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import React, { useReducer, useState } from "react"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"
import { EmailSignUpFormRegisterMutation } from "./__generated__/EmailSignUpFormRegisterMutation.graphql"
import { EmailSignUpFormSendVerificationEmailMutation } from "./__generated__/EmailSignUpFormSendVerificationEmailMutation.graphql"
import emailSignUpFormReducer from "./util/emailSignUpFormStateReducer"

interface Props extends TestIDProps {
  setIsPageLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export class SignUpQueryParams {
  email?: string
  wetoken?: string
}

function EmailSignUpForm({ setIsPageLoading, testid }: Props) {
  const params = useQueryParams<{ email?: string; wetoken?: string }>()
  const [isLoading, setIsLoading] = useState(false)
  const [showEmailVerification, setShowEmailVerification] = useState(false)
  const [showTurnstile, setShowTurnstile] = useState(false)

  const [formState, dispatchFormAction] = useReducer(emailSignUpFormReducer, {
    ...initialEmailSignUpFormState,
    email: params.email || "",
  })

  const { refreshAuthUser } = useAuthUser()
  const signupUser = useSignupUser()

  const history = useHistory()
  const classes = useStyles()

  const form = useFormStore<EmailSignUpFormRegisterMutation>(
    graphql`
      mutation EmailSignUpFormRegisterMutation($input: RegisterUserInput!) {
        response: registerUser(input: $input) {
          node {
            id
            firstName
            lastName
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      email: formState.email!,
      verificationCode: "",
    }
  )

  const sendEmailVerification =
    Relay.useAsyncMutation<EmailSignUpFormSendVerificationEmailMutation>(
      graphql`
        mutation EmailSignUpFormSendVerificationEmailMutation(
          $input: CommunitySignupSendVerificationEmailInput!
        ) {
          response: communitySignupSendVerificationEmail(input: $input) {
            data
            errors {
              field
              message
            }
          }
        }
      `
    )

  if (showTurnstile) {
    return (
      <Turnstile
        siteKey={TURNSTILE_SITE_KEY}
        onSuccess={handleTurnstileProcessed}
        options={{ theme: "light" }}
      />
    )
  }

  if (showEmailVerification) {
    return (
      <>
        <Form
          testid={testid}
          classes={{ buttonsRoot: classes.buttonsContainer }}
          buttons={
            <>
              <DiscoTextButton
                onClick={() => {
                  setShowEmailVerification(false)
                }}
                className={classes.backToPreviousButton}
              >
                {"Back"}
              </DiscoTextButton>
              <Form.SubmitButton
                testid={`${testid}.button.verify-email`}
                id={`${testid}Form`}
                form={form}
                onClick={handleSubmitVerificationCode}
                className={classes.verifyEmailButton}
                disabled={form.isSubmitting || !form.state.verificationCode}
              >
                {"Verify Email"}
              </Form.SubmitButton>
            </>
          }
        >
          <DiscoText variant={"body-md-600"} marginBottom={2}>
            {"Verification Required"}
          </DiscoText>
          <DiscoText variant={"body-sm"}>
            {`A verification code has been sent to ${formState.email!}. Enter the code below to complete your registration.`}
          </DiscoText>

          <DiscoDivider marginTop={3} marginBottom={3} />

          <DiscoFormControl
            errorMessages={form.errorsByField.verificationCode}
            marginBottom={0.5}
          >
            <DiscoInput
              fullWidth
              placeholder={"Verification Code"}
              name={"verification-code"}
              value={form.state.verificationCode || ""}
              onChange={(e) => (form.state.verificationCode = e.target.value)}
              data-testid={`${testid}.input.verification-code`}
            />
          </DiscoFormControl>
        </Form>

        <DiscoDivider marginTop={3} marginBottom={2} />
        <DiscoText variant={"body-sm"} align={"center"}>
          {
            "If you didn't receive the code, please go back to verify your information and try again."
          }
        </DiscoText>
      </>
    )
  }

  return (
    <>
      <Form
        testid={"EmailSignUpForm"}
        onSubmit={handleSubmit}
        knownErrorKeys={["email", "password"]}
        errorInfo={null}
        customClassName={classes.form}
      >
        <SocialLogin
          width={"100%"}
          redirectDestination={window.location.href}
          providers={[SocialLoginProvider.google]}
          loginSource={"sign_up"}
          queryParams={params}
        >
          <div className={classes.orText}>
            <DiscoText variant={"body-sm"} setColor={"#435369"}>
              {"Or"}
            </DiscoText>
          </div>
        </SocialLogin>
        <DiscoFormControl>
          <DiscoInput
            name={SIGNUP_FORM_STATE_TEXT_FIELD_NAMES.EMAIL}
            type={SIGNUP_FORM_STATE_TEXT_FIELD_NAMES.EMAIL}
            placeholder={"Your Work Email Address"}
            value={formState.email}
            onChange={handleTextFieldChange}
            className={classes.input}
            inputProps={{ "data-testid": "EmailSignUpForm.input-email" }}
          />
        </DiscoFormControl>
        <DiscoFormControl>
          <DiscoPasswordInput
            name={SIGNUP_FORM_STATE_TEXT_FIELD_NAMES.PASSWORD}
            type={SIGNUP_FORM_STATE_TEXT_FIELD_NAMES.PASSWORD}
            placeholder={"Password"}
            value={formState.password}
            onChange={handleTextFieldChange}
            className={classes.input}
            inputProps={{ "data-testid": "EmailSignUpForm.input-password" }}
          />
        </DiscoFormControl>
        <DiscoButton
          type={"submit"}
          shouldDisplaySpinner={isLoading}
          disabled={isLoading}
          testid={"EmailSignUpForm.submit-button"}
          width={"100%"}
        >
          {"Sign Up for Free"}
        </DiscoButton>
      </Form>
      <DiscoText
        variant={"body-sm"}
        color={"text.secondary"}
        align={"center"}
        marginTop={1.5}
      >
        {"By signing up for Disco, you agree to the "}
        <DiscoLink to={"https://www.disco.co/terms"} target={"_blank"}>
          <DiscoText variant={"body-sm"} color={"primary.main"} display={"inline"}>
            {"terms of service"}
          </DiscoText>
        </DiscoLink>
        {" and the "}
        <DiscoLink to={"https://www.disco.co/privacy-policy"} target={"_blank"}>
          <DiscoText variant={"body-sm"} color={"primary.main"} display={"inline"}>
            {"privacy policy"}
          </DiscoText>
        </DiscoLink>
      </DiscoText>
      <DiscoText
        variant={"body-sm"}
        color={"text.secondary"}
        align={"center"}
        className={classes.haveAnAccountText}
      >
        {"Have an account? "}
        <DiscoLink
          to={{
            pathname: ROUTE_NAMES.AUTHENTICATION.LOGIN,
            state: { redirectUrl: location.href },
          }}
        >
          <DiscoText variant={"body-sm"} color={"primary.main"} display={"inline"}>
            {"Sign in"}
          </DiscoText>
        </DiscoLink>
      </DiscoText>
    </>
  )

  function handleTextFieldChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    dispatchFormAction({
      type: event.currentTarget.name as ValueOf<
        typeof SIGNUP_FORM_STATE_TEXT_FIELD_NAMES
      >,
      payload: event.currentTarget.value,
    })
  }

  async function handleSubmitVerificationCode() {
    // Call sign up user
    if (!form.state.verificationCode) {
      form.addError({
        field: "verificationCode",
        message: "Verification code is required",
      })
      return
    }
    const { didSave, response } = await form.submit(form.state)
    if (!didSave || !response?.node) return

    setIsPageLoading(true)
    await refreshAuthUser()

    history.replace({
      pathname: ROUTE_NAMES.ONBOARDING.V2.GETTING_STARTED,
      // Pass any marketing query params through to the next page
      search: location.search,
    })
    setShowEmailVerification(false)
  }

  async function handleTurnstileProcessed(token: string) {
    try {
      const res = await sendEmailVerification({
        input: {
          email: formState.email,
          password: formState.password,
          timezone: userSystemTimezone,
          wetoken: params.wetoken,
          turnstileToken: token,
        },
      })

      if (res.response?.errors) {
        setIsLoading(false)
        displayErrorToastWithArgs({
          message: res.response.errors[0].message,
          testid: "EmailSignUpForm.work-email.error",
          timeout: 10000,
        })
        sendFailedEmailVerificationEvent()
        setShowTurnstile(false)
        return
      }
    } catch (error) {
      setIsLoading(false)
      setShowTurnstile(false)
      displayErrorToast(
        "We are not able to validate your email at this time. Please try again."
      )
      return
    }
    // Call mutation to send email verification
    form.state.email = formState.email

    setShowEmailVerification(true)
    setShowTurnstile(false)
  }

  async function handleSubmit() {
    try {
      setIsLoading(true)

      // If email verification is required, show turnstile
      if (window.requiresEmailVerification) {
        setShowTurnstile(true)
        setIsLoading(false)
        return
      }

      // Eventually this will be removed
      await signupUser({
        email: formState.email,
        password: formState.password,
        timezone: userSystemTimezone,
      })

      setIsPageLoading(true)
      await refreshAuthUser()

      history.replace({
        pathname: ROUTE_NAMES.ONBOARDING.V2.GETTING_STARTED,
        // Pass any marketing query params through to the next page
        search: location.search,
      })
    } catch (error) {
      if (isApiError(error)) {
        dispatchFormAction({
          type: "SET_VALIDATION_OBJECT",
          payload: {
            validationObject: generateEmailSignUpFormValidationObjectFromApiError(
              error.data
            ),
          },
        })
      }

      displayErrorToast(error)
      setIsLoading(false)
      setIsPageLoading(false)
    }
  }

  function censorEmail(email: string) {
    const arr = email.split("@")
    if (arr[0].length <= 1) {
      return `*@${arr[1]}`
    }
    const censoredEmail = arr[0][0] + "*".repeat(arr[0].length - 2) + arr[0].slice(-1)
    return `${censoredEmail}@${arr[1]}`
  }

  function sendFailedEmailVerificationEvent() {
    try {
      FS.event("Attempted Sign Up with Free Email", {
        email: censorEmail(formState.email),
      })
    } catch {
      // do nothing
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  form: {
    display: "contents",
  },
  input: {
    borderRadius: theme.measure.borderRadius.big,
  },
  haveAnAccountText: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  orText: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  verifyEmailButton: {
    width: "100%",
    display: "flex",
    gap: theme.spacing(3),
    justifyContent: "center",
  },
  backToPreviousButton: {
    color: theme.palette.groovy.grey[400],
  },
  buttonsContainer: {
    marginTop: theme.spacing(1.5),
  },
}))

export default observer(EmailSignUpForm)
