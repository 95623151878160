import { BrainSearchUserMessageFragment$key } from "@/brain-search/internal/__generated__/BrainSearchUserMessageFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"

interface BrainSearchUserMessageProps extends TestIDProps {
  brainSearchMessageKey: BrainSearchUserMessageFragment$key
}

function BrainSearchUserMessage({ brainSearchMessageKey }: BrainSearchUserMessageProps) {
  const classes = useStyles()
  const theme = useTheme()

  const message = useFragment<BrainSearchUserMessageFragment$key>(
    graphql`
      fragment BrainSearchUserMessageFragment on BrainSearchMessage {
        id
        messageText
      }
    `,
    brainSearchMessageKey
  )

  return (
    <div key={message.id} className={classNames(classes.message, classes.fadeIn)}>
      <DiscoEditor
        key={message.id}
        readOnly
        disableGutter
        markdown={message.messageText}
        fontSize={"14px"}
        backgroundColor={"transparent"}
        textColor={theme.palette.common.white}
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  message: {
    width: "100%",
    padding: theme.spacing(1, 2),
    borderRadius: theme.measure.borderRadius.big,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.primary.main,
  },
  fadeIn: {
    animation: "$fadeIn 1s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

export default BrainSearchUserMessage
