import { BrainSearchParams } from "@/brain-search/internal/BrainSearchLandingPage"
import BrainSearchSidebarHeader from "@/brain-search/internal/BrainSearchSidebarHeader"
import BrainSearchSidebarList from "@/brain-search/internal/BrainSearchSidebarList"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { DiscoIcon, DiscoSideBar, DiscoSideBarItem, DiscoSideBarProps } from "@disco-ui"
import usePermissions from "@utils/hook/usePermissions"
import { useQueryParams } from "@utils/url/urlUtils"

export default function BrainSearchSidebar(props: Partial<DiscoSideBarProps>) {
  const classes = useStyles()
  const { searchId } = useQueryParams<BrainSearchParams>()

  const activeOrganization = useActiveOrganization()!
  const permissions = usePermissions(activeOrganization)
  const canManageBrainSearchSettings = permissions.has("organization_settings.manage")

  return (
    <DiscoSideBar
      {...props}
      fullHeight
      data-testid={"BrainSearchSidebar"}
      header={<BrainSearchSidebarHeader />}
      items={
        <div>
          <ScrollShadowContainer classes={{ scrollContainer: classes.itemsContainer }}>
            {/* New Conversation */}
            <DiscoSideBarItem
              name={"New Conversation"}
              to={ROUTE_NAMES.BRAIN.ROOT}
              leftIcon={<DiscoIcon icon={"search"} />}
              selected={isNewSearchActive()}
            />

            {/* Conversations */}
            <BrainSearchSidebarList />
          </ScrollShadowContainer>
        </div>
      }
      adminActions={
        canManageBrainSearchSettings ? (
          <DiscoSideBarItem
            leftIcon={<DiscoIcon icon={"settings"} />}
            name={"Settings"}
            to={{
              pathname: ROUTE_NAMES.ADMIN.AI.ROOT,
              search: "?isRedirect=1",
            }}
          />
        ) : undefined
      }
    />
  )

  function isNewSearchActive() {
    if (searchId) return false
    if (location.pathname === ROUTE_NAMES.BRAIN.ROOT) return true
    return false
  }
}

const useStyles = makeUseStyles((theme) => ({
  itemsContainer: {
    flex: "auto",
    display: "inline-flex",
    flexFlow: "column",
    flexShrink: 0,
    minHeight: "100%",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(2),
  },
}))
