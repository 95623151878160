/**
 * @generated SignedSource<<90550c2a83dfd511cb8c0c5188cd6357>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrainSearchMessageFeedbackFooterFragment$data = {
  readonly brainSearchMessageFeedback: {
    readonly rating: number;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "BrainSearchMessageFeedbackFooterFragment";
};
export type BrainSearchMessageFeedbackFooterFragment$key = {
  readonly " $data"?: BrainSearchMessageFeedbackFooterFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrainSearchMessageFeedbackFooterFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrainSearchMessageFeedbackFooterFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrainSearchMessageFeedbackNode",
      "kind": "LinkedField",
      "name": "brainSearchMessageFeedback",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rating",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrainSearchMessage",
  "abstractKey": null
};

(node as any).hash = "b4bbbfec5c20bceefbb9766774a1b467";

export default node;
