import { useSearchClientFragment$key } from "@/apps/util/hooks/__generated__/useSearchClientFragment.graphql"
import { useSearchClientRefetchQuery } from "@/apps/util/hooks/__generated__/useSearchClientRefetchQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import { SearchTypes } from "@/search/utils/searchUtils"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"
import { camelize } from "inflected"
import { useMemo } from "react"
import { useRefetchableFragment } from "react-relay"
import { graphql } from "relay-runtime"
import TypesenseInstantSearchAdapter, {
  TypesenseInstantsearchAdapterOptions,
} from "typesense-instantsearch-adapter"

export default function useSearchClient() {
  const activeOrganization = useActiveOrganization()
  const { globalSearch } = useFeatureFlags()
  const subscriptionStanding = useSubscriptionStanding()

  const [organization, refetch] = useRefetchableFragment<
    useSearchClientRefetchQuery,
    useSearchClientFragment$key
  >(
    graphql`
      fragment useSearchClientFragment on Organization
      @refetchable(queryName: "useSearchClientRefetchQuery") {
        id
        searchConfig
        viewerMembership {
          searchOptOuts {
            edges {
              node {
                id
                role
                entity
              }
            }
          }
        }
      }
    `,
    activeOrganization || null
  )

  const searchConfig = organization?.searchConfig
  const searchOptOuts = new Set(
    Relay.connectionToArray(organization?.viewerMembership?.searchOptOuts).map((optOut) =>
      camelize(optOut.entity, false)
    )
  )
  const allEntitiesOptedOutOf = searchOptOuts.size === SearchTypes.length

  const { searchClient } = useMemo(() => {
    if (!searchConfig) return { searchClient: null }
    if (allEntitiesOptedOutOf) return { searchClient: null }
    return new TypesenseInstantSearchAdapter(
      JSON.parse(searchConfig) as TypesenseInstantsearchAdapterOptions
    )
  }, [searchConfig, allEntitiesOptedOutOf])

  if (
    !globalSearch ||
    !organization ||
    !searchClient ||
    allEntitiesOptedOutOf ||
    subscriptionStanding !== "active"
  )
    return null

  const indexName = Relay.fromGlobalId(organization.id).id

  const refreshSearch = () => {
    refetch({}, { fetchPolicy: "network-only" })
  }

  return { searchClient, indexName, refreshSearch, searchOptOuts }
}
