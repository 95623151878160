import { ValueOf } from "../../../../utils/typeUtils"
import {
  EmailSignUpFormValidationObject,
  initialEmailSignUpFormState,
  SIGNUP_FORM_STATE_TEXT_FIELD_NAMES,
} from "./emailSignUpFormConstants"

type EmailSignUpFormStateReducerAction =
  | {
      type: ValueOf<typeof SIGNUP_FORM_STATE_TEXT_FIELD_NAMES>
      payload: string
    }
  | {
      type: "SET_VALIDATION_OBJECT"
      payload: {
        validationObject: EmailSignUpFormValidationObject
      }
    }

function emailSignUpFormStateReducer(
  state: typeof initialEmailSignUpFormState,
  action: EmailSignUpFormStateReducerAction
): typeof initialEmailSignUpFormState {
  let newState = state

  switch (action.type) {
    case SIGNUP_FORM_STATE_TEXT_FIELD_NAMES.EMAIL:
    case SIGNUP_FORM_STATE_TEXT_FIELD_NAMES.PASSWORD: {
      newState = {
        ...state,
        [action.type]: action.payload,
      }

      break
    }

    case "SET_VALIDATION_OBJECT": {
      newState = {
        ...state,
        validationObject: action.payload.validationObject,
      }

      break
    }

    default:
      break
  }

  return newState
}

export default emailSignUpFormStateReducer
