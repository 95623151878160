import { BRAIN_SEARCH_MESSAGES_PER_LOAD } from "@/brain-search/internal/BrainSearchMessageList"
import BrainSearchPageContent from "@/brain-search/internal/BrainSearchPageContent"
import { BrainSearchPageQuery } from "@/brain-search/internal/__generated__/BrainSearchPageQuery.graphql"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import Relay from "@/relay/relayUtils"
import { DiscoSpinner } from "@disco-ui"
import { useLazyLoadQuery } from "react-relay"
import { Redirect, useParams } from "react-router-dom"
import { graphql } from "relay-runtime"

export type BrainParams = {
  searchId?: string
  initialPrompt?: string
}

function BrainSearchPage() {
  const { searchId } = useParams<BrainParams>()

  const { node } = useLazyLoadQuery<BrainSearchPageQuery>(
    graphql`
      query BrainSearchPageQuery($brainSearchId: ID!, $first: Int!, $after: String) {
        node(id: $brainSearchId) {
          ... on BrainSearch {
            __typename
            id
            ...BrainSearchPageContentFragment @arguments(first: $first, after: $after)
          }
        }
      }
    `,
    {
      brainSearchId: searchId || "",
      first: BRAIN_SEARCH_MESSAGES_PER_LOAD,
      after: null,
    },
    { fetchPolicy: "network-only" }
  )

  const brainSearch = Relay.narrowNodeType(node, "BrainSearch")
  if (!brainSearch) {
    return <Redirect to={ROUTE_NAMES.BRAIN.ROOT} />
  }

  return <BrainSearchPageContent brainSearchKey={brainSearch} />
}

export default Relay.withSkeleton({
  component: BrainSearchPage,
  skeleton: () => <DiscoSpinner size={"lg"} absoluteCenter />,
})
