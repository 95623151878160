/**
 * @generated SignedSource<<e48f6894d5e27e8cd79369d8b25a413f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrainSearchPageContentFragment$data = {
  readonly id: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"BrainSearchMessageList_PaginationFragment" | "BrainSearchPageHeaderFragment">;
  readonly " $fragmentType": "BrainSearchPageContentFragment";
};
export type BrainSearchPageContentFragment$key = {
  readonly " $data"?: BrainSearchPageContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrainSearchPageContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrainSearchPageContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BrainSearchPageHeaderFragment"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "kind": "FragmentSpread",
      "name": "BrainSearchMessageList_PaginationFragment"
    }
  ],
  "type": "BrainSearch",
  "abstractKey": null
};

(node as any).hash = "5e7b681beac733a81c131a482e5bbdd5";

export default node;
