import { ApiErrorShape } from "../../../../core/network-manager/networkModels"
import { generateSpecificFieldError } from "../../../../utils/error/errorUtils"
import {
  EmailSignUpFormValidationObject,
  initialEmailSignUpFormState,
  SIGNUP_FORM_STATE_TEXT_FIELD_NAMES,
} from "./emailSignUpFormConstants"

function generateEmailSignUpFormValidationObjectFromState(
  state: typeof initialEmailSignUpFormState
): EmailSignUpFormValidationObject {
  const newValidationObject: EmailSignUpFormValidationObject = {}

  if (!state[SIGNUP_FORM_STATE_TEXT_FIELD_NAMES.EMAIL]) {
    newValidationObject[SIGNUP_FORM_STATE_TEXT_FIELD_NAMES.EMAIL] = [
      "Enter an email address",
    ]
  }

  return newValidationObject
}

function generateEmailSignUpFormValidationObjectFromApiError(
  errorInfo: ApiErrorShape
): EmailSignUpFormValidationObject {
  const getFieldError = generateSpecificFieldError(errorInfo)
  const newValidationObject: EmailSignUpFormValidationObject = {}

  if (getFieldError("email")?.length) {
    newValidationObject[SIGNUP_FORM_STATE_TEXT_FIELD_NAMES.EMAIL] = getFieldError("email")
  }

  return newValidationObject
}

export {
  generateEmailSignUpFormValidationObjectFromState,
  generateEmailSignUpFormValidationObjectFromApiError,
}
