import { ProductAppKind } from "@/apps/sidebar-item/__generated__/AppSidebarItemFragment.graphql"
import { useAppLevel } from "@/apps/util/appLevelContext"
import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"

// NOTE: Changing the order of the apps in this array with change the order that they are rendered in
const ALWAYS_CONNECTABLE_APPS: ProductAppKind[] = [
  "posts",
  "chat_channel",
  "collection",
  "content",
  "link",
  "group_directory",
]

// NOTE: Changing the order of the apps in this array with change the order that they are rendered in
const CONNECTABLE_APPS: ProductAppKind[] = [
  "curriculum",
  "events",
  "resources",
  "slack",
  "organization_events",
  ...ALWAYS_CONNECTABLE_APPS,
]

const ORGANIZATION_APPS: ProductAppKind[] = [
  "posts",
  "link",
  "content",
  "collection",
  "organization_events",
  "chat_channel",
  "group_directory",
]

export default function useUnconnectedProductApps(): ProductAppKind[] {
  const { connectedApps } = useConnectedProductApps()
  const { product } = useAppLevel()
  return CONNECTABLE_APPS.filter((kind) => {
    if (!product && !ORGANIZATION_APPS.includes(kind)) return false

    // Cannot add an organization events app to a product
    if (product && kind === "organization_events") return false

    if (ALWAYS_CONNECTABLE_APPS.includes(kind)) return true
    return !connectedApps.has(kind)
  })
}
