interface EmailSignUpFormValidationObject {
  email?: string[]
  password?: string[]
}

const SIGNUP_FORM_STATE_TEXT_FIELD_NAMES = {
  EMAIL: "email",
  PASSWORD: "password",
} as const

const initialEmailSignUpFormState = {
  [SIGNUP_FORM_STATE_TEXT_FIELD_NAMES.EMAIL]: "",
  [SIGNUP_FORM_STATE_TEXT_FIELD_NAMES.PASSWORD]: "",
  validationObject: {} as EmailSignUpFormValidationObject,
}

export { SIGNUP_FORM_STATE_TEXT_FIELD_NAMES, initialEmailSignUpFormState }
export { EmailSignUpFormValidationObject }
