import useStartNewBrainSearch from "@/admin/integrations/chat-bot/util/useStartNewBrainSearch"
import BrainSearchInput from "@/brain-search/internal/BrainSearchInput"
import BrainSearchSuggestionList from "@/brain-search/internal/BrainSearchSuggestionList"
import { BrainSearchLandingPageMutation } from "@/brain-search/internal/__generated__/BrainSearchLandingPageMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import classNames from "classnames"
import { ConnectionHandler } from "react-relay"
import { graphql } from "relay-runtime"

export type BrainSearchParams = {
  searchId?: GlobalID
}

function BrainSearchLandingPage() {
  const activeOrganization = useActiveOrganization()
  const { authUser } = useAuthUser()
  const classes = useStyles()
  const isMobile = useIsMobile()
  const startNewBrainSearch = useStartNewBrainSearch()

  const form = useFormStore<BrainSearchLandingPageMutation>(
    graphql`
      mutation BrainSearchLandingPageMutation(
        $input: CreateBrainSearchInput!
        $connections: [ID!]!
      ) {
        response: createBrainSearch(input: $input) {
          node
            @prependNode(connections: $connections, edgeTypeName: "BrainSearchNodeEdge") {
            ...BrainSearchSidebarList_ItemFragment @relay(mask: false)
          }
          errors {
            field
            message
          }
        }
      }
    `,
    { title: "" }
  )

  return (
    <div className={classNames(classes.root, classes.fadeIn)}>
      <div className={classes.container}>
        {/* Headers */}
        <div className={classes.headerContainer}>
          <DiscoText variant={"heading-lg-700"} color={"text.primary"}>
            {authUser?.firstName ? `Hello, ${authUser?.firstName}.` : "Hello."}
          </DiscoText>
          <DiscoText
            variant={"body-md"}
            color={"groovy.neutral.400"}
            marginBottom={isMobile ? 0 : 4}
            className={classes.subHeader}
          >
            {"What would you like to learn today?"}
          </DiscoText>
        </div>

        <div className={classes.inputContainer}>
          {/* Input */}
          <BrainSearchInput
            placeholder={"Ask a question..."}
            onChange={handleChangeQuery}
            onSubmit={handleSubmit}
            clearOnSubmit={false}
            classes={{ root: classes.inputRoot }}
          />

          {/* Suggestions */}
          <BrainSearchSuggestionList form={form} onSelect={handleSubmit} />
        </div>
      </div>
    </div>
  )

  function handleChangeQuery(text: string) {
    form.state.title = text
  }

  async function handleSubmit() {
    if (!form.state.title) return

    const { response, didSave } = await form.submit(form.state, {
      connections: [
        ConnectionHandler.getConnectionID(
          activeOrganization!.viewerMembership!.id,
          "BrainSearchSidebarList__brainSearches"
        ),
      ],
    })
    if (!didSave || !response?.node?.id) return

    startNewBrainSearch(response.node.id, form.state.title)
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 2),
    height: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3.5),
    height: "100%",
    width: "100%",
    maxWidth: "1400px",

    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(4),
    },
  },
  subHeader: {
    fontWeight: 400,
  },
  inputContainer: {
    width: "100%",
    maxWidth: "689px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 auto",
    gap: theme.spacing(3.5),
  },
  inputRoot: {
    width: "100%",
    maxWidth: "485px",
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 0,
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    textAlign: "center",
  },
  fadeIn: {
    animation: "$fadeIn 0.5s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

export default BrainSearchLandingPage
