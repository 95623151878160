/**
 * @generated SignedSource<<c03d4815688586a0b219c12b55e49f69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrainSearchPageHeaderFragment$data = {
  readonly id: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"BrainSearchMoreActionsButtonFragment">;
  readonly " $fragmentType": "BrainSearchPageHeaderFragment";
};
export type BrainSearchPageHeaderFragment$key = {
  readonly " $data"?: BrainSearchPageHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrainSearchPageHeaderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrainSearchPageHeaderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BrainSearchMoreActionsButtonFragment"
    }
  ],
  "type": "BrainSearch",
  "abstractKey": null
};

(node as any).hash = "d9bf66b3b2c919e581ea03f386eae03a";

export default node;
