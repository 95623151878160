/**
 * @generated SignedSource<<b49ab6277545222e90eda998aad69b6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BrainSearchMessageType = "assistant" | "user" | "%future added value";
export type BrainSearchPageContent__createMessageMutation$variables = {
  id: string;
  userText: string;
};
export type BrainSearchPageContent__createMessageMutation$data = {
  readonly createBrainSearchMessage: {
    readonly assistantMessage: {
      readonly id: string;
      readonly messageText: string;
      readonly sources: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          };
        }>;
      } | null;
      readonly type: BrainSearchMessageType;
    };
    readonly userMessage: {
      readonly id: string;
      readonly messageText: string;
      readonly type: BrainSearchMessageType;
    };
  };
};
export type BrainSearchPageContent__createMessageMutation = {
  response: BrainSearchPageContent__createMessageMutation$data;
  variables: BrainSearchPageContent__createMessageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userText"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messageText",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brainSearchId",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "userText",
        "variableName": "userText"
      }
    ],
    "concreteType": "CreateBrainSearchMessageResponse",
    "kind": "LinkedField",
    "name": "createBrainSearchMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrainSearchMessage",
        "kind": "LinkedField",
        "name": "userMessage",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BrainSearchMessage",
        "kind": "LinkedField",
        "name": "assistantMessage",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrainSearchMessageSourceNodeConnection",
            "kind": "LinkedField",
            "name": "sources",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrainSearchMessageSourceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrainSearchMessageSource",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrainSearchPageContent__createMessageMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrainSearchPageContent__createMessageMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "4d72e822d57fa9352803eeaa838af038",
    "id": null,
    "metadata": {},
    "name": "BrainSearchPageContent__createMessageMutation",
    "operationKind": "mutation",
    "text": "mutation BrainSearchPageContent__createMessageMutation(\n  $id: ID!\n  $userText: String!\n) {\n  createBrainSearchMessage(brainSearchId: $id, userText: $userText) {\n    userMessage {\n      id\n      messageText\n      type\n    }\n    assistantMessage {\n      id\n      messageText\n      type\n      sources {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ae558fed57f0912c648bd67afd806d3";

export default node;
