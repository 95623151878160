import { BrainSearchMessageInternalSourceFragment$key } from "@/brain-search/internal/__generated__/BrainSearchMessageInternalSourceFragment.graphql"
import CommunityBadge from "@/community/CommunityBadge"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import LockIcon from "@/core/ui/iconsax/bold/lock.svg"
import CertifiedBadge from "@/core/ui/iconsax/bold/verify.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"
import { useHistory } from "react-router-dom"

interface Props extends TestIDProps {
  brainSearchMessageSourceKey: BrainSearchMessageInternalSourceFragment$key
}

function BrainSearchMessageInternalSource({ brainSearchMessageSourceKey }: Props) {
  const source = useFragment<BrainSearchMessageInternalSourceFragment$key>(
    graphql`
      fragment BrainSearchMessageInternalSourceFragment on BrainSearchMessageSource {
        id
        entity
        entityId
        drawerSearchParams {
          contentId
          assetId
          postId
          u
        }
        content {
          id
          name
        }
        asset {
          id
          name
        }
        trainingData {
          id
          name
          url
        }
      }
    `,
    brainSearchMessageSourceKey
  )

  const handleClickSource = getClickSourceHandler()
  const isLocked = !handleClickSource // Sources are locked if they don't have access to it anymore

  const activeOrganization = useActiveOrganization()
  const classes = useStyles({ isLocked })
  const history = useHistory()

  return (
    <DiscoContainerButton
      key={source.id}
      className={classNames(classes.source, classes.fadeIn)}
      onClick={handleClickSource}
    >
      <div className={classes.topSource}>
        <DiscoText variant={"body-xs-700"} color={"text.primary"} truncateText={3}>
          {getTitle()}
        </DiscoText>

        {isLocked ? (
          <LockIcon className={classes.icon} width={16} height={16} />
        ) : (
          <CertifiedBadge className={classes.icon} width={16} height={16} />
        )}
      </div>
      <div className={classes.bottomSource}>
        <div className={classes.expert}>
          {activeOrganization && (
            <CommunityBadge
              organizationKey={activeOrganization}
              size={24}
              classes={{ badge: classes.badge }}
            />
          )}
          <DiscoText variant={"body-xs"} color={"text.secondary"} truncateText={1}>
            {activeOrganization?.name}
          </DiscoText>
        </div>
      </div>
    </DiscoContainerButton>
  )

  function getTitle() {
    switch (source.entity) {
      case "content":
        if (!source.content) return "Content"
        return source.content.name
      case "asset":
        if (!source.asset) return "Asset"
        return source.asset.name
      case "training_data":
        if (!source.trainingData) return "Training Data"
        return source.trainingData.name
      default:
        return "Source"
    }
  }

  function getClickSourceHandler() {
    switch (source.entity) {
      case "content":
      case "asset":
        return handleClickContentOrAssetSource()
      case "training_data":
        return handleClickTrainingDataSource()
      default:
        return undefined
    }
  }

  function handleClickTrainingDataSource() {
    if (source.trainingData?.url) {
      return () => window.open(source.trainingData!.url, "_blank")
    }
    return undefined
  }

  function handleClickContentOrAssetSource() {
    if (source.drawerSearchParams?.contentId) {
      return () => {
        history.push({
          pathname: location.pathname,
          search: setSearchParams<GlobalDrawerParams<"adminContent">>(location.search, {
            contentId: source.drawerSearchParams.contentId!,
          }),
        })
      }
    } else if (source.drawerSearchParams?.assetId) {
      return () => {
        history.push({
          pathname: location.pathname,
          search: setSearchParams<GlobalDrawerParams<"asset">>(location.search, {
            assetId: source.drawerSearchParams.assetId!,
          }),
        })
      }
    } else if (source.drawerSearchParams?.postId) {
      return () => {
        history.push({
          pathname: location.pathname,
          search: setSearchParams<GlobalDrawerParams<"post">>(location.search, {
            postId: source.drawerSearchParams.postId!,
          }),
        })
      }
    } else if (source.drawerSearchParams?.u) {
      return () => {
        history.push({
          pathname: location.pathname,
          search: setSearchParams<GlobalDrawerParams<"contentUsage">>(location.search, {
            u: source.drawerSearchParams.u!,
          }),
        })
      }
    }
    return undefined
  }
}

const useStyles = makeUseStyles((theme) => ({
  source: ({ isLocked }: { isLocked: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.groovy.neutral[100],
    border: `1px solid ${theme.palette.groovy.neutral[100]}`,
    minHeight: "80px",
    minWidth: "140px",

    "&:hover": {
      borderColor: theme.palette.groovy.neutral[300],
      backgroundColor: theme.palette.groovy.neutral[200],
      boxShadow:
        "0px 2px 4px 0px rgba(63, 63, 70, 0.04), 0px 6px 18px 0px rgba(63, 63, 70, 0.08)",
    },
    ...(isLocked
      ? {
          cursor: "default",
          pointerEvents: "none",
        }
      : {
          cursor: "pointer",
          pointerEvents: "auto",
        }),
  }),
  topSource: {
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
    flex: 1,
    gap: theme.spacing(1),
  },
  icon: {
    right: 0,
    flexShrink: 0,
  },
  bottomSource: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  expert: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
    width: "100%",
  },
  badge: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
  },
  fadeIn: {
    animation: "$fadeIn 1s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

export function BrainSearchMessageSourceSkeleton() {
  const classes = useStyles()

  return (
    <DiscoContainerButton className={classes.source}>
      <div className={classes.topSource}>
        <DiscoTextSkeleton width={"100%"} />
      </div>
      <div className={classes.bottomSource}>
        <div className={classes.expert}>
          <div className={classes.badge} />
          <DiscoTextSkeleton width={"50%"} />
        </div>
      </div>
    </DiscoContainerButton>
  )
}

export default observer(BrainSearchMessageInternalSource)
