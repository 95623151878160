/**
 * @generated SignedSource<<71eda8b8750c3077603c7f5bc8a58ebb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrainSearchMoreActionsButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteBrainSearchButtonFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "BrainSearchMoreActionsButtonFragment";
};
export type BrainSearchMoreActionsButtonFragment$key = {
  readonly " $data"?: BrainSearchMoreActionsButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrainSearchMoreActionsButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrainSearchMoreActionsButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteBrainSearchButtonFragment"
    }
  ],
  "type": "BrainSearch",
  "abstractKey": null
};

(node as any).hash = "90f6f2bc7073d4e1ad48f26ecd785b18";

export default node;
