/**
 * @generated SignedSource<<d3c981b2089af34f8fe5d2377e84fa4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationOnboardingInfoSectionFragment$data = {
  readonly cover: string | null;
  readonly id: string;
  readonly name: string;
  readonly registrationDescription: string | null;
  readonly " $fragmentType": "OrganizationOnboardingInfoSectionFragment";
};
export type OrganizationOnboardingInfoSectionFragment$key = {
  readonly " $data"?: OrganizationOnboardingInfoSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationOnboardingInfoSectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationOnboardingInfoSectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDescription",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3f9c74720f9e40f441257d2c0ce5dde5";

export default node;
