import BrainSearchMoreActionsButton from "@/brain-search/internal/BrainSearchMoreActionsButton"
import { BrainSearchPageHeaderFragment$key } from "@/brain-search/internal/__generated__/BrainSearchPageHeaderFragment.graphql"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconButton, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"
import { useHistory } from "react-router-dom"

interface Props extends TestIDProps {
  brainSearchKey: BrainSearchPageHeaderFragment$key
}

function BrainSearchPageHeader({ brainSearchKey }: Props) {
  const classes = useStyles()
  const history = useHistory()

  const brainSearch = useFragment<BrainSearchPageHeaderFragment$key>(
    graphql`
      fragment BrainSearchPageHeaderFragment on BrainSearch {
        id
        title
        ...BrainSearchMoreActionsButtonFragment
      }
    `,
    brainSearchKey
  )

  return (
    <div className={classes.header}>
      <div className={classes.lhsHeader}>
        <DiscoIconButton onClick={goBack}>
          <DiscoIcon
            icon={"arrow-stem-left"}
            width={24}
            height={24}
            className={classes.icon}
          />
        </DiscoIconButton>
        <DiscoText variant={"body-md-500"} color={"text.primary"}>
          {brainSearch.title}
        </DiscoText>
      </div>

      <div className={classes.rhsHeader}>
        <BrainSearchMoreActionsButton brainSearchKey={brainSearch} />
      </div>
    </div>
  )

  function goBack() {
    history.push(ROUTE_NAMES.BRAIN.ROOT)
  }
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.raise1,
    padding: theme.spacing(2, 2, 2, 3),
    backgroundColor: theme.palette.background.paper,
    height: theme.mixins.toolbar.minHeight,
    borderBottom: theme.palette.constants.borderSmall,
  },
  lhsHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  rhsHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  icon: {
    color: `${theme.palette.groovy.neutral[700]} !important`,
  },
}))

export default BrainSearchPageHeader
