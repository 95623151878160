import BrainSearchMessageSourceList from "@/brain-search/internal/BrainSearchMessageSourceList"
import { BrainSearchAssistantMessageFragment$key } from "@/brain-search/internal/__generated__/BrainSearchAssistantMessageFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"
import BrainSearchMessageFeedbackFooter from "./BrainSearchMessageFeedbackFooter"

interface Props extends TestIDProps {
  brainSearchMessageKey: BrainSearchAssistantMessageFragment$key
  generatingMessageId: GlobalID | null
}

function BrainSearchAssistantMessage({
  brainSearchMessageKey,
  generatingMessageId,
}: Props) {
  const classes = useStyles()

  const message = useFragment<BrainSearchAssistantMessageFragment$key>(
    graphql`
      fragment BrainSearchAssistantMessageFragment on BrainSearchMessage {
        id
        messageText
        ...BrainSearchMessageFeedbackFooterFragment
      }
    `,
    brainSearchMessageKey
  )
  if (!message) return null

  const isGenerating = generatingMessageId === message.id

  return (
    <div key={message.id} className={classNames(classes.message, classes.fadeIn)}>
      {/* Sources */}
      <BrainSearchMessageSourceList brainSearchMessageId={message.id} />

      {/* Answer Tag */}
      <div className={classes.answerTag}>
        <DiscoIcon icon={"stars"} width={16} height={16} color={"text.primary"} active />
        <DiscoText variant={"heading-xs-700"}>{"Answer"}</DiscoText>
      </div>

      {/* Answer */}
      <DiscoEditor
        key={message.messageText}
        readOnly
        disableGutter
        markdown={message.messageText}
        fontSize={"14px"}
        disableScroll
        className={classes.editor}
      />
      {!isGenerating && (
        <BrainSearchMessageFeedbackFooter brainSearchMessageKey={message} />
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  message: {
    width: "100%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  answerTag: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  editor: {
    padding: theme.spacing(1, 2),
    borderRadius: theme.measure.borderRadius.big,
  },
  fadeIn: {
    animation: "$fadeIn 0.5s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

export default BrainSearchAssistantMessage
