/**
 * @generated SignedSource<<1dfae7352c9f66b965ec9c8c0458d69c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TrackBrainSearchMessageFeedbackInput = {
  brainSearchMessageId: string;
  comment?: string | null;
  rating: number;
};
export type BrainSearchMessageFeedbackFooter__RateReponseMutation$variables = {
  input: TrackBrainSearchMessageFeedbackInput;
};
export type BrainSearchMessageFeedbackFooter__RateReponseMutation$data = {
  readonly trackBrainSearchMessageFeedback: {
    readonly node: {
      readonly brainSearchMessage: {
        readonly brainSearchMessageFeedback: {
          readonly rating: number;
        } | null;
      };
    };
  };
};
export type BrainSearchMessageFeedbackFooter__RateReponseMutation = {
  response: BrainSearchMessageFeedbackFooter__RateReponseMutation$data;
  variables: BrainSearchMessageFeedbackFooter__RateReponseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "BrainSearchMessageFeedbackNode",
  "kind": "LinkedField",
  "name": "brainSearchMessageFeedback",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rating",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrainSearchMessageFeedbackFooter__RateReponseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TrackBrainSearchMessageFeedbackResponse",
        "kind": "LinkedField",
        "name": "trackBrainSearchMessageFeedback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrainSearchMessageFeedbackNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrainSearchMessage",
                "kind": "LinkedField",
                "name": "brainSearchMessage",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrainSearchMessageFeedbackFooter__RateReponseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TrackBrainSearchMessageFeedbackResponse",
        "kind": "LinkedField",
        "name": "trackBrainSearchMessageFeedback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrainSearchMessageFeedbackNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrainSearchMessage",
                "kind": "LinkedField",
                "name": "brainSearchMessage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b59febe3b5eaa8869f878b2e2c0b8ba",
    "id": null,
    "metadata": {},
    "name": "BrainSearchMessageFeedbackFooter__RateReponseMutation",
    "operationKind": "mutation",
    "text": "mutation BrainSearchMessageFeedbackFooter__RateReponseMutation(\n  $input: TrackBrainSearchMessageFeedbackInput!\n) {\n  trackBrainSearchMessageFeedback(input: $input) {\n    node {\n      brainSearchMessage {\n        brainSearchMessageFeedback {\n          rating\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a90fba530bceb6ec467120edd76ffb6";

export default node;
