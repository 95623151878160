import { BrainSearchParams } from "@/brain-search/internal/BrainSearchLandingPage"
import BrainSearchMoreActionsButton from "@/brain-search/internal/BrainSearchMoreActionsButton"
import { BrainSearchMoreActionsButtonFragment$key } from "@/brain-search/internal/__generated__/BrainSearchMoreActionsButtonFragment.graphql"
import { BrainSearchSidebarListPaginationQuery } from "@/brain-search/internal/__generated__/BrainSearchSidebarListPaginationQuery.graphql"
import { BrainSearchSidebarListQuery } from "@/brain-search/internal/__generated__/BrainSearchSidebarListQuery.graphql"
import { BrainSearchSidebarList_PaginationFragment$key } from "@/brain-search/internal/__generated__/BrainSearchSidebarList_PaginationFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoSideBarItem, DiscoSideBarItemSkeleton, DiscoText } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { useTheme } from "@material-ui/core"
import { useQueryParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import {
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"

const BRAIN_SEARCHES_PER_PAGE = 40

function BrainSearchSidebarList() {
  const activeOrganization = useActiveOrganization()!
  const organizationMembershipId = activeOrganization.viewerMembership?.id
  const classes = useStyles()
  const { searchId } = useQueryParams<BrainSearchParams>()
  const theme = useTheme()

  const { membership } = useLazyLoadQuery<BrainSearchSidebarListQuery>(
    graphql`
      query BrainSearchSidebarListQuery($id: ID!, $first: Int, $after: String) {
        membership: node(id: $id) {
          ... on OrganizationMembership {
            ...BrainSearchSidebarList_PaginationFragment
              @arguments(first: $first, after: $after)
          }
        }
      }
    `,
    {
      id: organizationMembershipId || "",
      first: BRAIN_SEARCHES_PER_PAGE,
    },
    { fetchPolicy: "network-only" }
  )

  const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment<
    BrainSearchSidebarListPaginationQuery,
    BrainSearchSidebarList_PaginationFragment$key
  >(
    graphql`
      fragment BrainSearchSidebarList_PaginationFragment on OrganizationMembership
      @refetchable(queryName: "BrainSearchSidebarListPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        id
        brainSearches(first: $first, after: $after)
          @connection(key: "BrainSearchSidebarList__brainSearches") {
          __id
          edges {
            node {
              ...BrainSearchSidebarList_ItemFragment @relay(mask: false)
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    membership
  )

  useSubscribeToInvalidationState(
    data?.brainSearches?.__id ? [data?.brainSearches.__id] : [],
    refetchBrainSearches
  )

  const brainSearches = Relay.connectionToArray(data?.brainSearches)
  if (!brainSearches.length)
    return (
      <DiscoText
        variant={"body-sm"}
        color={theme.palette.type === "dark" ? "groovy.onDark.400" : "groovy.neutral.300"}
        marginTop={2}
      >
        {"No conversations yet."}
      </DiscoText>
    )

  const sections = getSections()

  return (
    <>
      {sections.map((section) => {
        if (!section.brainSearches.length) return null
        return (
          <DiscoSideBarItem
            key={section.name}
            name={section.name}
            indentNestedItems
            classes={{ content: classes.container }}
            collapsible
          >
            {section.brainSearches.map((brainSearch) => (
              <DiscoSideBarItem
                key={brainSearch.id}
                classes={{ content: classes.content }}
                name={brainSearch.title}
                nameNode={
                  <DiscoText variant={"body-sm-500"} truncateText={1}>
                    {brainSearch.title}
                  </DiscoText>
                }
                to={generatePath(ROUTE_NAMES.BRAIN.SEARCH, {
                  searchId: brainSearch.id,
                })}
                selected={isSelected(brainSearch.id)}
                showOnHoverRightContent={
                  <BrainSearchMoreActionsButton brainSearchKey={brainSearch} />
                }
              />
            ))}
          </DiscoSideBarItem>
        )
      })}

      {hasNext && (
        <DiscoScrolledIntoView
          onScrolledIntoView={() => loadNext(BRAIN_SEARCHES_PER_PAGE)}
          isLoading={isLoadingNext}
          skeleton={<DiscoSideBarItemSkeleton />}
        />
      )}
    </>
  )

  function getSections() {
    type BrainSearch = {
      id: GlobalID
      title: string
      createdAt: string
    } & BrainSearchMoreActionsButtonFragment$key

    const todaySection = {
      name: "Today",
      brainSearches: [] as BrainSearch[],
    }
    const last7DaysSection = {
      name: "Last 7 Days",
      brainSearches: [] as BrainSearch[],
    }
    const last30DaysSection = {
      name: "Last 30 Days",
      brainSearches: [] as BrainSearch[],
    }
    const moreThan30DaysAgoSection = {
      name: "More Than 30 Days Ago",
      brainSearches: [] as BrainSearch[],
    }

    const now = new Date()
    const nowFullYear = now.getFullYear()
    const nowMonth = now.getMonth()
    const nowDate = now.getDate()

    const today = new Date(nowFullYear, nowMonth, nowDate)
    const last7Days = new Date(nowFullYear, nowMonth, nowDate - 7)
    const last30Days = new Date(nowFullYear, nowMonth, nowDate - 30)

    for (const brainSearch of brainSearches) {
      const createdAt = new Date(brainSearch.createdAt)
      if (createdAt >= today) {
        todaySection.brainSearches.push(brainSearch)
      } else if (createdAt >= last7Days) {
        last7DaysSection.brainSearches.push(brainSearch)
      } else if (createdAt >= last30Days) {
        last30DaysSection.brainSearches.push(brainSearch)
      } else {
        moreThan30DaysAgoSection.brainSearches.push(brainSearch)
      }
    }

    return [todaySection, last7DaysSection, last30DaysSection, moreThan30DaysAgoSection]
  }

  function refetchBrainSearches() {
    if (!organizationMembershipId) return
    refetch({
      first: BRAIN_SEARCHES_PER_PAGE,
    })
  }

  function isSelected(id: GlobalID) {
    // Check if on that path
    const path = generatePath(ROUTE_NAMES.BRAIN.SEARCH, { searchId: id })
    if (location.pathname === path) return true

    // Check if the searchId is in the URL params
    if (searchId === id) return true

    return false
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    cursor: "default",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      "& svg, & p": {
        color: theme.palette.groovy.neutral[500],
      },
    },
  },
  content: {
    gridTemplateColumns: "1fr max-content",
  },
}))

function BrainSearchSidebarListSkeleton() {
  return <DiscoSideBarItemSkeleton />
}

export default Relay.withSkeleton({
  component: observer(BrainSearchSidebarList),
  skeleton: BrainSearchSidebarListSkeleton,
})

export const BRAIN_SEARCH_SIDEBAR_ITEM_FRAGMENT = graphql`
  fragment BrainSearchSidebarList_ItemFragment on BrainSearch {
    id
    title
    createdAt
    ...BrainSearchMoreActionsButtonFragment
  }
`
