/**
 * @generated SignedSource<<71ef041cf4ffe83763b025976ccc1c6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrainSearchMessageSourceListFragment$data = {
  readonly externalSources: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "BrainSearchMessageExternalSource";
        readonly id: string;
        readonly url: string;
        readonly " $fragmentSpreads": FragmentRefs<"BrainSearchMessageExternalSourceFragment">;
      };
    }>;
    readonly totalCount: number;
  } | null;
  readonly id: string;
  readonly sources: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "BrainSearchMessageSource";
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"BrainSearchMessageInternalSourceFragment">;
      };
    }>;
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "BrainSearchMessageSourceListFragment";
};
export type BrainSearchMessageSourceListFragment$key = {
  readonly " $data"?: BrainSearchMessageSourceListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrainSearchMessageSourceListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./BrainSearchMessageSourceListRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "BrainSearchMessageSourceListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrainSearchMessageSourceNodeConnection",
      "kind": "LinkedField",
      "name": "sources",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BrainSearchMessageSourceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BrainSearchMessageSource",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "BrainSearchMessageInternalSourceFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrainSearchMessageExternalSourceNodeConnection",
      "kind": "LinkedField",
      "name": "externalSources",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BrainSearchMessageExternalSourceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BrainSearchMessageExternalSource",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "BrainSearchMessageExternalSourceFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "BrainSearchMessage",
  "abstractKey": null
};
})();

(node as any).hash = "03e3d183b380ffe48f7e274caacb25a3";

export default node;
