import BrainSearchAssistantMessage from "@/brain-search/internal/BrainSearchAssistantMessage"
import BrainSearchUserMessage from "@/brain-search/internal/BrainSearchUserMessage"
import { BrainSearchMessageListPaginationQuery } from "@/brain-search/internal/__generated__/BrainSearchMessageListPaginationQuery.graphql"
import {
  BrainSearchMessageList_PaginationFragment$data,
  BrainSearchMessageList_PaginationFragment$key,
} from "@/brain-search/internal/__generated__/BrainSearchMessageList_PaginationFragment.graphql"
import { GlobalID, NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoSpinner, DiscoText } from "@disco-ui"
import { graphql, usePaginationFragment } from "react-relay"

export type BrainSearchMessage = NodeFromConnection<
  NonNullable<BrainSearchMessageList_PaginationFragment$data["messages"]>
>

export const BRAIN_SEARCH_MESSAGES_PER_LOAD = 100

interface BrainSearchMessageListProps {
  brainSearchKey: BrainSearchMessageList_PaginationFragment$key
  isBotThinking: boolean
  generatingMessageId: GlobalID | null
}

function BrainSearchMessageList({
  brainSearchKey,
  isBotThinking,
  generatingMessageId,
}: BrainSearchMessageListProps) {
  const classes = useStyles()

  const { data } = usePaginationFragment<
    BrainSearchMessageListPaginationQuery,
    BrainSearchMessageList_PaginationFragment$key
  >(
    graphql`
      fragment BrainSearchMessageList_PaginationFragment on BrainSearch
      @refetchable(queryName: "BrainSearchMessageListPaginationQuery")
      @argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
        messages(first: $first, after: $after)
          @connection(key: "BrainSearchMessageList__messages") {
          totalCount
          edges {
            node {
              id
              type
              ...BrainSearchUserMessageFragment
              ...BrainSearchAssistantMessageFragment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    brainSearchKey
  )

  const messages = Relay.connectionToArray(data?.messages)

  return (
    <div className={classes.container}>
      <div className={classes.messageWrapper}>
        {messages.map((message) => {
          switch (message.type) {
            case "user":
              return (
                <BrainSearchUserMessage
                  key={message.id}
                  brainSearchMessageKey={message}
                />
              )
            case "assistant":
              // Only show assistant message once it's done generating
              if (generatingMessageId === message.id && isBotThinking) return null
              return (
                <BrainSearchAssistantMessage
                  brainSearchMessageKey={message}
                  generatingMessageId={generatingMessageId}
                />
              )
            default: {
              return null
            }
          }
        })}

        {isBotThinking && (
          <div className={classes.thinkingContainer}>
            <DiscoIcon
              icon={"stars"}
              width={16}
              height={16}
              color={"text.primary"}
              active
            />
            <DiscoText variant={"heading-xs-700"}>{"Generating response"}</DiscoText>
            <div className={classes.typingAnimation}>
              <span />
              <span />
              <span />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    // Reversing this container ensures that the page starts off scrolled to the visual bottom of the page.
    // This also makes sure when new messages are rendered in, the page stays scrolled to the visual bottom of the chat
    // in a way that we don't need to manually scroll down to continue reading the bot's response.
    flexDirection: "column-reverse",
    alignItems: "center",
    width: "100%",
    overflowY: "auto",
    // flexGrow: 1,
    padding: theme.spacing(2),
    gap: theme.spacing(3),
  },
  messageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "688px",
    gap: theme.spacing(3),
    flexGrow: 1, // This ensures that when there aren't many messages, the first message gets visually pushed to the top of the page
  },
  thinkingContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  typingAnimation: {
    display: "inline-flex",
    alignItems: "center",
    height: "16px",
    "& span": {
      width: "4px",
      height: "4px",
      margin: "0 1px",
      backgroundColor: theme.palette.text.primary,
      borderRadius: "50%",
      display: "inline-block",
      animation: "$bounce 1.4s infinite ease-in-out both",
    },
    "& span:nth-child(1)": {
      animationDelay: "-0.32s",
    },
    "& span:nth-child(2)": {
      animationDelay: "-0.16s",
    },
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes bounce": {
    "0%, 80%, 100%": {
      transform: "scale(0)",
    },
    "40%": {
      transform: "scale(1)",
    },
  },
}))

export default Relay.withSkeleton({
  component: BrainSearchMessageList,
  skeleton: () => <DiscoSpinner absoluteCenter />,
})
