import {
  LegacyEditSetupAppModalFormState,
  LegacyEditSetupAppModalFormStore,
} from "@/apps/list/modal/SetupAppModal"
import { useEditAppFormStoreFragment$key } from "@/apps/util/hooks/__generated__/useEditAppFormStoreFragment.graphql"
import { useEditAppFormStoreMutation } from "@/apps/util/hooks/__generated__/useEditAppFormStoreMutation.graphql"
import { useEditAppFormStoreQuery } from "@/apps/util/hooks/__generated__/useEditAppFormStoreQuery.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { useFragment } from "react-relay"
import { commitLocalUpdate, ConnectionHandler, graphql } from "relay-runtime"

function useEditAppFormStore(
  appId: GlobalID | undefined
): LegacyEditSetupAppModalFormStore {
  const { node } = Relay.useSkippableLazyLoadQuery<useEditAppFormStoreQuery>(
    graphql`
      query useEditAppFormStoreQuery($id: ID!) {
        node(id: $id) {
          ... on ProductApp {
            ...useEditAppFormStoreFragment
          }
        }
      }
    `,
    { id: appId || "" },
    { skip: !appId, fetchPolicy: "network-only" }
  )

  const app = useFragment<useEditAppFormStoreFragment$key>(
    graphql`
      fragment useEditAppFormStoreFragment on ProductApp {
        id
        customAppTitle
        customAppDescription
        customAppUrl
        showOnDashboard
        visibility
        navSectionId
        linkProductId
        kind
        curriculum {
          showCompletedUsers
        }
        visibilityGroups {
          edges {
            node {
              id
            }
          }
        }
        visibilityMembers {
          edges {
            node {
              id
            }
          }
        }
        feed {
          name
          allowPostsFrom
          allowPostsGroups {
            edges {
              node {
                id
              }
            }
          }
          allowPostsMembers {
            edges {
              node {
                id
              }
            }
          }
        }
        badge {
          kind
          icon
          color
          emoji
          mediaUrl
        }
        appDirectoryItems {
          edges {
            node {
              id
              memberGroup {
                id
              }
            }
          }
        }
      }
    `,
    node || null
  )

  const form = useFormStore<
    useEditAppFormStoreMutation,
    LegacyEditSetupAppModalFormState
  >(
    graphql`
      mutation useEditAppFormStoreMutation($input: UpdateProductAppInput!) {
        response: updateProductApp(input: $input) {
          node {
            id
            navSectionId
            collection {
              id
              ...usePermissionsFragment
            }
            feed {
              id
              ...usePermissionsFragment
              allowPostsFrom
              allowPostsGroups {
                edges {
                  node {
                    id
                  }
                }
              }
              allowPostsMembers {
                edges {
                  node {
                    id
                  }
                }
              }
            }
            contentUsage {
              id
            }
            product {
              slug
              dashboard {
                id
                blocks(position: main) {
                  edges {
                    node {
                      id
                      kind
                      ...DashboardBlockItemFragment
                    }
                  }
                }
              }
              curriculum {
                showCompletedUsers
              }
            }
            organization {
              ...AppsSidebarList_OrganizationFragment
            }
            navSection {
              ...AppsSidebarList_NavSectionFragment
            }
            ...useEditAppFormStoreFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: app?.id || "",
      customAppTitle: app?.customAppTitle || app?.feed?.name,
      customAppDescription: app?.customAppDescription,
      customAppUrl: app?.customAppUrl,
      showOnDashboard: app?.showOnDashboard,
      visibility: app?.visibility,
      visibilityGroups: Relay.connectionToArray(app?.visibilityGroups).map((g) => g.id),
      visibilityMembers: Relay.connectionToArray(app?.visibilityMembers).map((m) => m.id),
      allowPostsFrom: app?.feed?.allowPostsFrom,
      allowPostsGroups: Relay.connectionToArray(app?.feed?.allowPostsGroups).map(
        (g) => g.id
      ),
      allowPostsMembers: Relay.connectionToArray(app?.feed?.allowPostsMembers).map(
        (om) => om.id
      ),
      badge: app?.badge,
      navSectionId: app?.navSectionId,
      linkProductId: app?.linkProductId,
      showCompletedUsers: app?.curriculum?.showCompletedUsers,
      appDirectoryItems: Relay.connectionToArray(app?.appDirectoryItems).map((item) => ({
        memberGroupId: item.memberGroup?.id,
      })),
      kind: app?.kind,
    },
    {
      onCompleted: ({ node: updatedApp }) => {
        if (!updatedApp || !app) return
        const { navSectionId: oldSectionId } = app
        const { navSectionId: newSectionId } = updatedApp
        if (oldSectionId && oldSectionId !== newSectionId) {
          commitLocalUpdate(RelayEnvironment, (store) => {
            const sectionRecord = store.get(oldSectionId)
            if (!sectionRecord) return
            const connection = ConnectionHandler.getConnection(
              sectionRecord,
              "AppsSidebarList_NavSectionFragment__apps"
            )
            if (!connection) return
            Relay.deleteNodeFromConnection(connection, updatedApp.id)
          })
        }
      },
    }
  )
  return form
}

export default useEditAppFormStore
