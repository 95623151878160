import BrainSearchInput from "@/brain-search/internal/BrainSearchInput"
import BrainSearchMessageList from "@/brain-search/internal/BrainSearchMessageList"
import { BrainParams } from "@/brain-search/internal/BrainSearchPage"
import BrainSearchPageHeader from "@/brain-search/internal/BrainSearchPageHeader"
import { BrainSearchPageContentFragment$key } from "@/brain-search/internal/__generated__/BrainSearchPageContentFragment.graphql"
import AiApi from "@/common/AiApi"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import RestfulUtil from "@/core/restful/RestfulUtil"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayRestfulErrorToast } from "@components/toast/ToastProvider"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { useFragment } from "react-relay"
import { default as ConnectionHandlerPlus } from "relay-connection-handler-plus"
import { commitLocalUpdate, graphql, RecordSourceProxy } from "relay-runtime"
import { BrainSearchPageContent__createMessageMutation } from "./__generated__/BrainSearchPageContent__createMessageMutation.graphql"

interface BrainSearchPageContentProps {
  brainSearchKey: BrainSearchPageContentFragment$key
}

function BrainSearchPageContent({ brainSearchKey }: BrainSearchPageContentProps) {
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const [{ initialPrompt }, setQueryParams] = useQueryParamState<BrainParams>()
  const abortControllerRef = useRef<AbortController>(new AbortController())
  const [generatingMessageId, setGeneratingMessageId] = useState<GlobalID | null>(null)
  const [isBotThinking, setIsBotThinking] = useState(false)

  useEffect(() => {
    if (!initialPrompt) return

    handleCreateMessage(decodeURIComponent(initialPrompt))
    setQueryParams({ initialPrompt: undefined }, "replace")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const brainSearch = useFragment<BrainSearchPageContentFragment$key>(
    graphql`
      fragment BrainSearchPageContentFragment on BrainSearch
      @argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
        id
        title
        ...BrainSearchPageHeaderFragment
        ...BrainSearchMessageList_PaginationFragment
          @arguments(first: $first, after: $after)
      }
    `,
    brainSearchKey
  )

  const commit = Relay.useAsyncMutation<BrainSearchPageContent__createMessageMutation>(
    graphql`
      mutation BrainSearchPageContent__createMessageMutation(
        $id: ID!
        $userText: String!
      ) {
        createBrainSearchMessage(brainSearchId: $id, userText: $userText) {
          userMessage {
            id
            messageText
            type
          }
          assistantMessage {
            id
            messageText
            type
            sources {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <div className={classNames(classes.root, classes.fadeIn)}>
      <div className={classes.container}>
        {/* Header */}
        <BrainSearchPageHeader brainSearchKey={brainSearch} />

        <div className={classes.contentContainer}>
          {/* Messages */}
          <BrainSearchMessageList
            brainSearchKey={brainSearch}
            isBotThinking={isBotThinking}
            generatingMessageId={generatingMessageId}
          />

          {/* Input */}
          <div className={classes.inputContainer}>
            <BrainSearchInput
              placeholder={"Ask a followup..."}
              onSubmit={handleCreateMessage}
              classes={{ root: classes.input }}
              multiline={false}
              isGenerating={generatingMessageId !== null}
              onAbort={handleAbort}
            />
          </div>
        </div>
      </div>
    </div>
  )

  function resetThinkingState() {
    setGeneratingMessageId(null)
    setIsBotThinking(false)
    abortControllerRef.current = new AbortController()
  }

  function handleAbort() {
    abortControllerRef.current.abort()
    resetThinkingState()
  }

  function getMessagesConnection(store: RecordSourceProxy, brainSearchId: GlobalID) {
    const brainSearchRecord = store.get(brainSearchId)
    if (!brainSearchRecord) return

    const messagesConnections = ConnectionHandlerPlus.getConnections(
      brainSearchRecord,
      "BrainSearchMessageList__messages"
    )
    if (!messagesConnections.length) return
    return messagesConnections[0]
  }

  async function handleCreateMessage(prompt: string) {
    if (!activeOrganization) return

    const { assistantMessage } = await createInitialMessages(prompt)

    setIsBotThinking(true)
    setGeneratingMessageId(assistantMessage.id)

    // Generate the response
    try {
      const { response } = await AiApi.generateBrainSearchResponse(
        {
          messageText: prompt,
          brainSearchId: brainSearch.id,
          assistantMessageId: assistantMessage.id,
        },
        abortControllerRef.current
      )

      // Handle any errors
      const hasError = RestfulUtil.hasError(response)
      if (hasError) {
        await displayRestfulErrorToast(response)
        return
      }
      if (!response.body) return

      // Handle the text response stream
      setIsBotThinking(false)
      await handleBrainSearchResponse(response.body, assistantMessage.id)
    } catch (error) {
      if (error instanceof Error && error.name === "AbortError") {
        resetThinkingState()
      } else {
        throw error
      }
    }

    // Invalidate the message so it's sources get refetched
    commitLocalUpdate(RelayEnvironment, (store) => {
      // Get the record for the assistant message
      const assistantMessageRecord = store.get(assistantMessage.id)
      if (!assistantMessageRecord) return

      assistantMessageRecord.invalidateRecord()
    })

    setGeneratingMessageId(null)
  }

  async function createInitialMessages(prompt: string) {
    const { createBrainSearchMessage } = await commit({
      id: brainSearch.id,
      userText: prompt,
    })
    const { userMessage, assistantMessage } = createBrainSearchMessage

    commitLocalUpdate(RelayEnvironment, (store) => {
      const messagesConnection = getMessagesConnection(store, brainSearch.id)
      if (!messagesConnection) return

      // Create a tmp user message record in the Relay Store
      const tmpUserMessage = Relay.fabricateNode(store, "BrainSearchMessage", {
        id: Relay.fromGlobalId(userMessage.id).id,
        messageText: prompt,
        type: "user",
      })
      Relay.insertNodeIntoPaginatedConnection(store, messagesConnection, tmpUserMessage)

      // Create a tmp assistant message record in the Relay Store
      const tmpAssistantMessage = Relay.fabricateNode(store, "BrainSearchMessage", {
        id: Relay.fromGlobalId(assistantMessage.id).id,
        messageText: "",
        type: "assistant",
      })
      Relay.insertNodeIntoPaginatedConnection(
        store,
        messagesConnection,
        tmpAssistantMessage
      )
    })

    return { userMessage, assistantMessage }
  }

  async function handleBrainSearchResponse(
    responseBody: ReadableStream<Uint8Array>,
    assistantMessageId: GlobalID
  ) {
    await RestfulUtil.handleStream(
      responseBody,
      (decodedChunk) => {
        if (isBotThinking) setIsBotThinking(false) // Set this to false as soon as it starts typing

        commitLocalUpdate(RelayEnvironment, (store) => {
          const tmpAssistantMessageRecord = store.get(assistantMessageId)
          if (!tmpAssistantMessageRecord) return

          const existingText = tmpAssistantMessageRecord
            .getValue("messageText")
            ?.toString()
          Relay.deepUpdate(store, tmpAssistantMessageRecord, {
            messageText: existingText + decodedChunk,
          })
        })

        return true
      },
      { abortController: abortControllerRef.current }
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
  },
  container: {
    display: "grid",
    gridTemplateRows: `${theme.mixins.toolbar.minHeight}px 1fr`,
    height: "100%",
    width: "100%",
  },
  contentContainer: {
    display: "grid",
    gridTemplateRows: "1fr 72px",
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(0, 2),
  },
  input: {
    width: "100%",
    maxWidth: "688px",
    border: `0.5px solid ${theme.palette.primary.main}`,
    minHeight: "48px",
    zIndex: theme.zIndex.raise1,

    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 2.5),
      width: "93%",
    },
  },
  fadeIn: {
    animation: "$fadeIn 1s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

export default observer(BrainSearchPageContent)
