/**
 * @generated SignedSource<<64c437e420ca0cad56be4c863b3b499b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CommunitySignupSendVerificationEmailInput = {
  email: string;
  password?: string | null;
  timezone: string;
  turnstileToken?: string | null;
  wetoken?: string | null;
};
export type EmailSignUpFormSendVerificationEmailMutation$variables = {
  input: CommunitySignupSendVerificationEmailInput;
};
export type EmailSignUpFormSendVerificationEmailMutation$data = {
  readonly response: {
    readonly data: boolean | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type EmailSignUpFormSendVerificationEmailMutation = {
  response: EmailSignUpFormSendVerificationEmailMutation$data;
  variables: EmailSignUpFormSendVerificationEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CommunitySignupSendVerificationResponse",
    "kind": "LinkedField",
    "name": "communitySignupSendVerificationEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailSignUpFormSendVerificationEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailSignUpFormSendVerificationEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a1de832a19a3d67694d8508b691186af",
    "id": null,
    "metadata": {},
    "name": "EmailSignUpFormSendVerificationEmailMutation",
    "operationKind": "mutation",
    "text": "mutation EmailSignUpFormSendVerificationEmailMutation(\n  $input: CommunitySignupSendVerificationEmailInput!\n) {\n  response: communitySignupSendVerificationEmail(input: $input) {\n    data\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d3ba08b18c92ea7d509f759ba2b4aa4";

export default node;
