/**
 * @generated SignedSource<<255f739d3e70fa66c41a2f4caf74bbc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookmarkButtonContentFragment$data = {
  readonly hasViewerBookmarked: boolean;
  readonly id: string;
  readonly " $fragmentType": "BookmarkButtonContentFragment";
};
export type BookmarkButtonContentFragment$key = {
  readonly " $data"?: BookmarkButtonContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookmarkButtonContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookmarkButtonContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerBookmarked",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "67b3c1ec187cb9c100e1e6ea28628f22";

export default node;
