/**
 * @generated SignedSource<<df682b7276184065a64046da7e931ae9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteBrainSearchButtonFragment$data = {
  readonly id: string;
  readonly organizationId: string;
  readonly " $fragmentType": "DeleteBrainSearchButtonFragment";
};
export type DeleteBrainSearchButtonFragment$key = {
  readonly " $data"?: DeleteBrainSearchButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteBrainSearchButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteBrainSearchButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    }
  ],
  "type": "BrainSearch",
  "abstractKey": null
};

(node as any).hash = "cf4062dbc7029c9e106854efb0844908";

export default node;
