/**
 * @generated SignedSource<<f2abb5fecaa267c3554bef650e81cae6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BrainSearchMessageSourceEntity = "asset" | "content" | "training_data" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BrainSearchMessageInternalSourceFragment$data = {
  readonly asset: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly content: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly drawerSearchParams: {
    readonly assetId: string | null;
    readonly contentId: string | null;
    readonly postId: string | null;
    readonly u: string | null;
  };
  readonly entity: BrainSearchMessageSourceEntity;
  readonly entityId: string;
  readonly id: string;
  readonly trainingData: {
    readonly id: string;
    readonly name: string;
    readonly url: string;
  } | null;
  readonly " $fragmentType": "BrainSearchMessageInternalSourceFragment";
};
export type BrainSearchMessageInternalSourceFragment$key = {
  readonly " $data"?: BrainSearchMessageInternalSourceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrainSearchMessageInternalSourceFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrainSearchMessageInternalSourceFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrainSearchMessageSourceDrawerSearchParams",
      "kind": "LinkedField",
      "name": "drawerSearchParams",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assetId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "u",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Asset",
      "kind": "LinkedField",
      "name": "asset",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrainingData",
      "kind": "LinkedField",
      "name": "trainingData",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrainSearchMessageSource",
  "abstractKey": null
};
})();

(node as any).hash = "be2856aab4620e5fdcc9f03adb5b4ea5";

export default node;
