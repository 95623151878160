/**
 * @generated SignedSource<<14d7cb701f4888ceb53e989ca0972a19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SearchOptOutEntity = "chat_channel" | "chat_message" | "content_usage" | "feed" | "occurrence" | "post" | "product" | "user" | "%future added value";
export type SearchOptOutRole = "admin" | "member" | "owner" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useSearchClientFragment$data = {
  readonly id: string;
  readonly searchConfig: string | null;
  readonly viewerMembership: {
    readonly searchOptOuts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entity: SearchOptOutEntity;
          readonly id: string;
          readonly role: SearchOptOutRole;
        };
      }>;
    };
  } | null;
  readonly " $fragmentType": "useSearchClientFragment";
};
export type useSearchClientFragment$key = {
  readonly " $data"?: useSearchClientFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSearchClientFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./useSearchClientRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "useSearchClientFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchConfig",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchOptOutNodeConnection",
          "kind": "LinkedField",
          "name": "searchOptOuts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SearchOptOutNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SearchOptOut",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "role",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "entity",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "6ce5cea8ce999f56e19a0ce8dc1635b5";

export default node;
