import BrainSearchMessageExternalSource from "@/brain-search/internal/BrainSearchMessageExternalSource"
import BrainSearchMessageInternalSource from "@/brain-search/internal/BrainSearchMessageInternalSource"
import { BrainSearchMessageSourceListFragment$key } from "@/brain-search/internal/__generated__/BrainSearchMessageSourceListFragment.graphql"
import { BrainSearchMessageSourceListQuery } from "@/brain-search/internal/__generated__/BrainSearchMessageSourceListQuery.graphql"
import { BrainSearchMessageSourceListRefetchQuery } from "@/brain-search/internal/__generated__/BrainSearchMessageSourceListRefetchQuery.graphql"
import CommunityBadge from "@/community/CommunityBadge"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoIcon, DiscoSection, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import {
  graphql,
  useLazyLoadQuery,
  useRefetchableFragment,
  useSubscribeToInvalidationState,
} from "react-relay"

const TOTAL_INITIAL_SOURCES_SHOWN = 4

interface Props extends TestIDProps {
  brainSearchMessageId: GlobalID
}

function BrainSearchMessageSourceList({ brainSearchMessageId }: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const [viewMore, setViewMore] = useState(false)
  const { isOpen, onToggle } = useDisclosure()
  const isMobile = useIsMobile()
  const activeOrganization = useActiveOrganization()!

  const { node } = useLazyLoadQuery<BrainSearchMessageSourceListQuery>(
    graphql`
      query BrainSearchMessageSourceListQuery($id: ID!) {
        node(id: $id) {
          __typename
          id
          ...BrainSearchMessageSourceListFragment
        }
      }
    `,
    { id: brainSearchMessageId },
    { fetchPolicy: "network-only" }
  )

  const [message, refetch] = useRefetchableFragment<
    BrainSearchMessageSourceListRefetchQuery,
    BrainSearchMessageSourceListFragment$key
  >(
    graphql`
      fragment BrainSearchMessageSourceListFragment on BrainSearchMessage
      @refetchable(queryName: "BrainSearchMessageSourceListRefetchQuery") {
        sources {
          totalCount
          edges {
            node {
              id
              __typename
              ...BrainSearchMessageInternalSourceFragment
            }
          }
        }
        externalSources {
          totalCount
          edges {
            node {
              id
              url
              __typename
              ...BrainSearchMessageExternalSourceFragment
            }
          }
        }
      }
    `,
    node
  )

  const externalSources = Relay.connectionToArray(message?.externalSources)
  const internalSources = Relay.connectionToArray(message?.sources)
  const sources = [...internalSources, ...externalSources]
  const { initialSources, moreSources } = arrangeSources()

  useSubscribeToInvalidationState([brainSearchMessageId], () => {
    refetch({ id: brainSearchMessageId }, { fetchPolicy: "network-only" })
  })

  if (!sources?.length) return null

  return (
    <div className={classes.sources}>
      <div className={classes.sourcesTag}>
        <DiscoIcon
          icon={"iconsax.global"}
          width={16}
          height={16}
          color={theme.palette.text.primary}
        />
        <DiscoText variant={"heading-xs-700"}>{"Sources"}</DiscoText>
      </div>

      {renderSourcesList()}
    </div>
  )

  function renderSourcesList() {
    if (isMobile) {
      const icons = (
        <div className={classes.sourceIcons}>
          {sources
            .slice(0, 3)
            .map((source) =>
              source.__typename === "BrainSearchMessageExternalSource" ? (
                <img
                  key={source.id}
                  alt={""}
                  src={`https://www.google.com/s2/favicons?domain=${
                    new URL(source.url).hostname
                  }`}
                  className={classes.expertAvatar}
                />
              ) : (
                <CommunityBadge
                  organizationKey={activeOrganization}
                  size={24}
                  classes={{ badge: classes.badge }}
                />
              )
            )}
        </div>
      )

      return (
        <DiscoSection className={classes.sources} padding={1.5}>
          <DiscoButton onClick={onToggle} className={classes.textBtn} variant={"text"}>
            <div className={classes.textBtnAdornment}>
              <DiscoIcon icon={"file"} width={16} height={16} />
              <DiscoText variant={"body-md-700"}>{"Sources"}</DiscoText>
            </div>

            <div className={classes.textBtnAdornment}>
              {icons}
              <DiscoIcon
                icon={isOpen ? "chevron" : "chevron-down"}
                width={16}
                height={16}
              />
            </div>
          </DiscoButton>
          {isOpen && (
            <div className={classes.sourcesList}>
              {sources.map((source) => {
                if (source.__typename === "BrainSearchMessageExternalSource") {
                  return (
                    <BrainSearchMessageExternalSource
                      key={source.id}
                      brainSearchMessageExternalSourceKey={source}
                    />
                  )
                }

                return (
                  <BrainSearchMessageInternalSource
                    key={source.id}
                    brainSearchMessageSourceKey={source}
                  />
                )
              })}
            </div>
          )}
        </DiscoSection>
      )
    }
    return (
      <div className={classes.sourcesList}>
        {getInitialSources().map((source) => {
          if (source.__typename === "BrainSearchMessageExternalSource") {
            return (
              <BrainSearchMessageExternalSource
                key={source.id}
                brainSearchMessageExternalSourceKey={source}
              />
            )
          }

          return (
            <BrainSearchMessageInternalSource
              key={source.id}
              brainSearchMessageSourceKey={source}
            />
          )
        })}

        {/* If there are > (TOTAL_INITIAL_SOURCES_SHOWN - 1) sources, add a "See More" */}
        {Boolean(moreSources.length) && (
          <DiscoContainerButton className={classes.source} onClick={toggleViewMore}>
            <div className={classes.topSource}>
              <div className={classes.expert}>
                {moreSources.slice(0, TOTAL_INITIAL_SOURCES_SHOWN - 1).map((s) => (
                  <div key={s.id} className={classes.expertAvatar} />
                ))}
              </div>
            </div>
            <div className={classes.bottomSource}>
              <DiscoText variant={"body-xs"} color={"text.secondary"}>
                {viewMore ? "See less" : `View ${moreSources.length} more`}
              </DiscoText>
            </div>
          </DiscoContainerButton>
        )}
      </div>
    )
  }

  function arrangeSources() {
    // If there are TOTAL_INITIAL_SOURCES_SHOWN or less sources, render normally
    if (sources.length <= TOTAL_INITIAL_SOURCES_SHOWN)
      return { initialSources: sources, moreSources: [] }

    // If there are more than 4 sources, render the first 3 and a "See More" button
    return {
      initialSources: sources.slice(0, TOTAL_INITIAL_SOURCES_SHOWN - 1),
      moreSources: sources.slice(TOTAL_INITIAL_SOURCES_SHOWN - 1),
    }
  }

  function getInitialSources() {
    if (viewMore) return sources
    return initialSources
  }

  function toggleViewMore() {
    setViewMore((prev) => !prev)
  }
}

const useStyles = makeUseStyles((theme) => ({
  sources: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  sourcesTag: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  sourcesList: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(140px, 1fr))",
    gridAutoRows: "1fr",
    gap: theme.spacing(1.5),
    flexWrap: "wrap",
  },
  source: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.groovy.neutral[100],
    border: `1px solid ${theme.palette.groovy.neutral[100]}`,
    minHeight: "80px",

    "&:hover": {
      borderColor: theme.palette.groovy.neutral[300],
      backgroundColor: theme.palette.groovy.neutral[200],
      boxShadow:
        "0px 2px 4px 0px rgba(63, 63, 70, 0.04), 0px 6px 18px 0px rgba(63, 63, 70, 0.08)",
    },
  },
  topSource: {
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
    flex: 1,
  },
  bottomSource: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  expert: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
  },
  expertAvatar: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    background:
      "radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%), var(--Colors-blue-600, #4D47DC)",
  },
  textBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
  },
  textBtnAdornment: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),

    "& > svg": {
      color: theme.palette.groovy.neutral[700],
    },
  },
  badge: {
    borderRadius: "50%",
  },
  sourceIcons: {
    display: "flex",
    gap: theme.spacing(0.5),
  },
}))

export default Relay.withSkeleton({
  component: BrainSearchMessageSourceList,
  skeleton: () => null,
})
