/**
 * @generated SignedSource<<0f5da3763335a81649dd42c09a41eb3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateBrainSearchInput = {
  title: string;
};
export type BrainSearchLandingPageMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateBrainSearchInput;
};
export type BrainSearchLandingPageMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly createdAt: string;
      readonly id: string;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"BrainSearchMoreActionsButtonFragment">;
    } | null;
  };
};
export type BrainSearchLandingPageMutation = {
  response: BrainSearchLandingPageMutation$data;
  variables: BrainSearchLandingPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerPermissions",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memberPermissions",
  "storageKey": null
},
v10 = [
  {
    "alias": "nonNullProductId",
    "args": null,
    "kind": "ScalarField",
    "name": "productId",
    "storageKey": null
  },
  (v7/*: any*/),
  (v9/*: any*/)
],
v11 = [
  {
    "alias": "nullableProductId",
    "args": null,
    "kind": "ScalarField",
    "name": "productId",
    "storageKey": null
  },
  (v7/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BrainSearchLandingPageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "CreateBrainSearchResponse",
        "kind": "LinkedField",
        "name": "createBrainSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrainSearch",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BrainSearchMoreActionsButtonFragment"
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BrainSearchLandingPageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "CreateBrainSearchResponse",
        "kind": "LinkedField",
        "name": "createBrainSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrainSearch",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "nonNullProductId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v9/*: any*/)
                    ],
                    "type": "Product",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "Event",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v11/*: any*/),
                    "type": "Comment",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v11/*: any*/),
                    "type": "Feed",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v11/*: any*/),
                    "type": "Post",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "nullableProductIdString",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "productId",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v9/*: any*/)
                    ],
                    "type": "ContentUsage",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v11/*: any*/),
                    "type": "ChatChannel",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v11/*: any*/),
                    "type": "Dashboard",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "AssignmentSubmission",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "PathwayGroup",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "BrainSearch",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organizationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "node",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "BrainSearchNodeEdge"
              }
            ]
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d919973a06ddd84ba740a5d66761375",
    "id": null,
    "metadata": {},
    "name": "BrainSearchLandingPageMutation",
    "operationKind": "mutation",
    "text": "mutation BrainSearchLandingPageMutation(\n  $input: CreateBrainSearchInput!\n) {\n  response: createBrainSearch(input: $input) {\n    node {\n      id\n      title\n      createdAt\n      ...BrainSearchMoreActionsButtonFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment BrainSearchMoreActionsButtonFragment on BrainSearch {\n  id\n  ...usePermissionsFragment\n  ...DeleteBrainSearchButtonFragment\n}\n\nfragment DeleteBrainSearchButtonFragment on BrainSearch {\n  id\n  organizationId\n}\n\nfragment usePermissionsFragment on Node {\n  __isNode: __typename\n  __typename\n  ... on Organization {\n    viewerPermissions\n  }\n  ... on Product {\n    nonNullProductId: id\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Event {\n    nonNullProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Comment {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Feed {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Post {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on ContentUsage {\n    nullableProductIdString: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on ChatChannel {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Dashboard {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on AssignmentSubmission {\n    nonNullProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on PathwayGroup {\n    viewerPermissions\n  }\n  ... on BrainSearch {\n    viewerPermissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4f51898b8e4e4097a4573afd73b7f2f";

export default node;
