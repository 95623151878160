import { BookmarkButtonContentFragment$key } from "@/bookmarks/__generated__/BookmarkButtonContentFragment.graphql"
import { BookmarkButtonContentUsageFragment$key } from "@/bookmarks/__generated__/BookmarkButtonContentUsageFragment.graphql"
import {
  BookmarkButtonCreateMutation,
  BookmarkKind,
} from "@/bookmarks/__generated__/BookmarkButtonCreateMutation.graphql"
import { BookmarkButtonDeleteMutation } from "@/bookmarks/__generated__/BookmarkButtonDeleteMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoIcon, DiscoIconButtonSkeleton, DiscoTooltip } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useTheme } from "@material-ui/core"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { graphql, useFragment, useMutation } from "react-relay"

interface Props {
  className?: string
  contentUsageKey?: BookmarkButtonContentUsageFragment$key
  contentKey: BookmarkButtonContentFragment$key
  showText?: boolean
  kind: BookmarkKind
  testid?: string
  children?: OverridableDiscoButtonChildren
  inHeader?: boolean
}

function BookmarkButton({
  contentUsageKey,
  contentKey,
  showText = false,
  inHeader = false,
  kind,
  testid = "BookmarkButton",
  children,
  className: customClassName,
}: Props) {
  const activeOrganization = useActiveOrganization()!
  const theme = useTheme()
  const classes = useStyles({ inHeader })

  const contentUsage = useFragment<BookmarkButtonContentUsageFragment$key>(
    graphql`
      fragment BookmarkButtonContentUsageFragment on ContentUsage {
        id
        hasViewerBookmarked
      }
    `,
    contentUsageKey || null
  )

  const content = useFragment<BookmarkButtonContentFragment$key>(
    graphql`
      fragment BookmarkButtonContentFragment on Content {
        id
        hasViewerBookmarked
      }
    `,
    contentKey
  )

  const createMutation = Relay.useAsyncMutation<BookmarkButtonCreateMutation>(
    graphql`
      mutation BookmarkButtonCreateMutation($input: CreateBookmarkInput!) {
        createBookmark(input: $input) {
          node {
            id
            content {
              id
              hasViewerBookmarked
            }
            contentUsage {
              id
              hasViewerBookmarked
            }
          }
        }
      }
    `
  )

  const [deleteMutation] = useMutation<BookmarkButtonDeleteMutation>(
    graphql`
      mutation BookmarkButtonDeleteMutation(
        $organizationId: ID!
        $contentId: ID!
        $contentUsageId: ID
      ) {
        deleteBookmark(
          organizationId: $organizationId
          contentId: $contentId
          contentUsageId: $contentUsageId
        ) {
          node {
            id @deleteRecord
            content {
              id
              hasViewerBookmarked
            }
            contentUsage {
              id
              hasViewerBookmarked
            }
          }
        }
      }
    `
  )

  if (!content && !contentUsage) return null

  const bookmarkable = contentUsage || content
  const { hasViewerBookmarked } = bookmarkable!
  const tooltipContent = hasViewerBookmarked ? "Remove from Bookmarks" : "Bookmark"

  if (children) {
    return (
      <OverridableDiscoButton
        onClick={toggleBookmark}
        color={"transparent"}
        testid={`${testid}.bookmark-overridable-button`}
        stopPropagation
      >
        {children}
      </OverridableDiscoButton>
    )
  }

  return (
    <div className={classNames(classes.container, customClassName)}>
      <DiscoTooltip content={tooltipContent}>
        <DiscoButton
          testid={`${testid}.bookmark-button`}
          size={"small"}
          leftIcon={
            <DiscoIcon
              icon={"iconsax.custom-archive"}
              active={hasViewerBookmarked}
              width={16}
              height={16}
              className={
                hasViewerBookmarked ? classes.bookmarkIconFill : classes.bookmarkIcon
              }
            />
          }
          color={"grey"}
          onClick={(e) => {
            toggleBookmark()
            e.stopPropagation()
          }}
          className={classes.bookmarkButton}
          customButtonColor={{
            color:
              theme.palette.type === "dark"
                ? theme.palette.groovy.onDark[200]
                : theme.palette.groovy.neutral[700],
            backgroundColor: theme.palette.background.paper,
            hover: {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.groovy.neutral[200],
            },
          }}
        >
          {showText && "Bookmark"}
        </DiscoButton>
      </DiscoTooltip>
    </div>
  )

  async function toggleBookmark() {
    if (hasViewerBookmarked) {
      deleteMutation({
        variables: {
          organizationId: activeOrganization.id,
          contentId: content.id,
          contentUsageId: contentUsage?.id,
        },
        optimisticUpdater: (store) => {
          const contentConnection = store.get(content.id)
          if (contentConnection) contentConnection.setValue(false, "hasViewerBookmarked")

          if (contentUsage) {
            const contentUsageConnection = store.get(contentUsage.id)
            if (contentUsageConnection)
              contentUsageConnection.setValue(false, "hasViewerBookmarked")
          }
        },
        onError(error) {
          displayErrorToast(error)
        },
      })
      displayToast({
        message: "Bookmark Removed",
        testid: "BookmarkButton.removed-toast",
      })
    } else {
      await createMutation({
        input: {
          organizationId: activeOrganization.id,
          contentId: content?.id,
          contentUsageId: contentUsage?.id,
          kind,
        },
      })
      displayToast({
        message: "Bookmark Added",
        testid: "BookmarkButton.added-toast",
      })
    }
  }
}

type StyleProps = {
  inHeader: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  bookmarkIcon: {
    "& > path": {
      color: `${
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[200]
          : theme.palette.groovy.neutral[700]
      } !important`,
    },

    "&:is(svg)": {
      width: "16px",
      height: "16px",
    },
  },
  bookmarkIconFill: {
    "& > path": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  bookmarkButton: ({ inHeader }: StyleProps) => ({
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[200]
        : theme.palette.groovy.neutral[700],
    gap: theme.spacing(0.25),
    padding: theme.spacing(1),
    height: "32px",
    "& span": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "24px",
    },
    "&:hover svg:not($bookmarkIconFill) path": {
      stroke: theme.palette.primary.main,
    },

    ...styleIf(inHeader, {
      padding: theme.spacing(1.5),
      height: "48px",
    }),
  }),
}))

export default Relay.withSkeleton({
  component: observer(BookmarkButton),
  skeleton: () => <DiscoIconButtonSkeleton />,
})
