/**
 * @generated SignedSource<<6ffb677e4ccff10057782b199c90d504>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvitationKind = "admin" | "member" | "%future added value";
export type OrganizationRole = "admin" | "member" | "owner" | "%future added value";
export type ProductRole = "instructor" | "manager" | "member" | "%future added value";
export type JoinCommunityPageQuery$variables = {
  id: string;
  inviteKey?: string | null;
  token?: string | null;
};
export type JoinCommunityPageQuery$data = {
  readonly organization: {
    readonly cover?: string | null;
    readonly description?: string | null;
    readonly id?: string;
    readonly isInviteKeyValid?: boolean;
    readonly marketingPage?: {
      readonly metaDescription: string | null;
      readonly metaImageUrl: string | null;
      readonly metaTitle: string | null;
    };
    readonly name?: string;
    readonly questions?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly body: string | null;
          readonly id: string;
          readonly richEditorBody: string | null;
        };
      }>;
    };
    readonly viewerInvitation?: {
      readonly email: string;
      readonly firstName: string | null;
      readonly kind: InvitationKind;
      readonly lastName: string | null;
      readonly organizationRole: OrganizationRole | null;
      readonly product: {
        readonly name: string;
      } | null;
      readonly productRole: ProductRole | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationOnboardingInfoSectionFragment">;
  } | null;
};
export type JoinCommunityPageQuery = {
  response: JoinCommunityPageQuery$data;
  variables: JoinCommunityPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inviteKey"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "QuestionNodeConnection",
  "kind": "LinkedField",
  "name": "questions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Question",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "body",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "richEditorBody",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaTitle",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaDescription",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaImageUrl",
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationRole",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productRole",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "key",
      "variableName": "inviteKey"
    }
  ],
  "kind": "ScalarField",
  "name": "isInviteKeyValid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JoinCommunityPageQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LandingPage",
                "kind": "LinkedField",
                "name": "marketingPage",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "Invitation",
                "kind": "LinkedField",
                "name": "viewerInvitation",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v19/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationOnboardingInfoSectionFragment"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "JoinCommunityPageQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LandingPage",
                "kind": "LinkedField",
                "name": "marketingPage",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "Invitation",
                "kind": "LinkedField",
                "name": "viewerInvitation",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registrationDescription",
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e900a2d2766d45e4a7bf0d1f7a311aac",
    "id": null,
    "metadata": {},
    "name": "JoinCommunityPageQuery",
    "operationKind": "query",
    "text": "query JoinCommunityPageQuery(\n  $id: ID!\n  $token: String\n  $inviteKey: String\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      id\n      questions {\n        edges {\n          node {\n            id\n            body\n            richEditorBody\n          }\n        }\n      }\n      name\n      description\n      cover\n      marketingPage {\n        metaTitle\n        metaDescription\n        metaImageUrl\n        id\n      }\n      viewerInvitation(token: $token) {\n        email\n        kind\n        firstName\n        lastName\n        organizationRole\n        productRole\n        product {\n          name\n          id\n        }\n        id\n      }\n      isInviteKeyValid(key: $inviteKey)\n      ...OrganizationOnboardingInfoSectionFragment\n    }\n    id\n  }\n}\n\nfragment OrganizationOnboardingInfoSectionFragment on Organization {\n  id\n  cover\n  name\n  registrationDescription\n}\n"
  }
};
})();

(node as any).hash = "548c61778b18302ffe2cde2fead7d64f";

export default node;
