import { DeleteBrainSearchButtonFragment$key } from "@/brain-search/internal/__generated__/DeleteBrainSearchButtonFragment.graphql"
import { DeleteBrainSearchButtonMutation } from "@/brain-search/internal/__generated__/DeleteBrainSearchButtonMutation.graphql"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useState } from "react"
import { useFragment, useMutation } from "react-relay"
import { graphql } from "relay-runtime"

type Props = {
  children: OverridableDiscoButtonChildren
  brainSearchKey: DeleteBrainSearchButtonFragment$key
  onDelete: VoidFunction
}

const DeleteBrainSearchButton: React.FC<Props> = ({
  children,
  brainSearchKey,
  onDelete,
}) => {
  const [isModalOpen, setConfirmationModal] = useState<boolean>(false)

  const brainSearch = useFragment<DeleteBrainSearchButtonFragment$key>(
    graphql`
      fragment DeleteBrainSearchButtonFragment on BrainSearch {
        id
        organizationId
      }
    `,
    brainSearchKey
  )

  const [commit, isDeleting] = useMutation<DeleteBrainSearchButtonMutation>(graphql`
    mutation DeleteBrainSearchButtonMutation($id: ID!) {
      response: deleteBrainSearch(id: $id) {
        deletedBrainSearchId @deleteRecord
      }
    }
  `)

  return (
    <>
      <OverridableDiscoButton onClick={openConfirmationModal}>
        {children}
      </OverridableDiscoButton>
      <DiscoWarningModal
        testid={"DeleteBrainSearchConfirmationModal"}
        isOpen={isModalOpen}
        onClose={() => setConfirmationModal(false)}
        title={"Are you sure you want to permanently delete this conversation?"}
        description={
          "This action is irreversible. You will not be able to recover this conversation or its message history."
        }
        confirmationButtonProps={{
          onClick: deleteBrainSearch,
          shouldDisplaySpinner: isDeleting,
          children: "Yes, delete this conversation",
        }}
        modalContentLabel={"Delete Conversation Dialog"}
      />
    </>
  )

  function openConfirmationModal() {
    setConfirmationModal(true)
  }

  function deleteBrainSearch() {
    onDelete()
    commit({
      variables: { id: brainSearch.id },
      onCompleted() {
        displayToast({ message: "Successfully deleted" })
        setConfirmationModal(false)
      },
      onError() {
        displayErrorToast("An error occurred while trying to delete conversation.")
      },
    })
  }
}

export default DeleteBrainSearchButton
